import React, { useState } from 'react';
import Edpuzzle from '../../../../../assets/editor/provider/logo-edpuzzle.png';
import Genially from '../../../../../assets/editor/provider/logo-genially.png';
import useGeneral from '../../../../../hooks/useGeneral';
import AsideMenuButton from './AsideMenuButton/AsideMenuButton';
import BlockTemplatesMenu from './BlockTemplatesMenu/BlockTemplatesMenu';
import CollectionsMenu from './CollectionsMenu/CollectionsMenu';
import ItemBlockPreview from './ItemBlockPreview/ItemBlockPreview';
import VariantsMenu from './VariantsMenu/VariantsMenu';

// import { sendAction } from "../../../../../../state/socket/actions";
// import {insertBlockTemplate} from "../../../../../../state/lessons/actions"
/*
 * Contiene los datos de listado de itemBlocks
 * Gestiona el estado que determina que mostrar
 * */

const CreateBlockSidebar = ({ isOpen, close, blockList, onSelect, onClickNewTemplateBlock }) => {
  const [collection, setCollection] = useState(-1);
  const { eventsEditor, t } = useGeneral();

  const closeMenu = () => {
    close();
    setCollection(-1);
  };

  const addItemBlock = item => {
    // En item tenemos {caption: "Paragraph", identity: {family: "text", type: "text", variant: "paragraph"}, src: "http://localhost:8081/mintcontent/assets/blocks/text_paragraph@2x.png"}}
    if (item && item.identity) onSelect(item.identity.type, item.identity.family, item.identity.variant);
    // closeMenu();
  };

  function isShowBlockTemplates() {
    return collection && blockList[collection] && blockList[collection].id === 'blockTemplates';
  }

  function _onClickNewTemplateBlock() {
    closeMenu();
    onClickNewTemplateBlock && onClickNewTemplateBlock();
  }

  function _onClickTemplateBlock(templateBlock) {
    // == Creamos el componente Pending
    onSelect('pending', 'pending', 'pending', true, (itemCreated, lessonUpdated) => {
      // == Aqui nos notifican cuando se ha creado el item nuevo
      // == Notificamos al servidor que se quiere insertar un bloque template
      // TODO: Adaptar esto
      /*  dispatch(sendAction(insertBlockTemplate({
        "blockTemplateId": templateBlock.id,
        "lesson": lessonUpdated,
        "pendingItemId": itemCreated.id
      }))) */
    });
  }

  if (!blockList) return null;
  return (
    <div className='create-block-sidebar'>
      <CollectionsMenu open={isOpen} closeMenu={closeMenu}>
        {blockList.map((group, idx) => (
          <AsideMenuButton selected={idx === collection} key={group.id} onClick={() => setCollection(idx)}>
            {t(group.id, { ns: 'editor' })}
          </AsideMenuButton>
        ))}
        {/* TODO esto es provisional para quitar cuando tenga funcionalidad y llevarlo a blocklist.js*/}
        <AsideMenuButton
          selected={false}
          onClick={() => addItemBlock({ identity: { type: 'provider', family: 'provider', variant: 'edpuzzle' } })}
        >
          <img src={Edpuzzle} alt='Edpuzzle' /> edpuzzle
        </AsideMenuButton>
        <AsideMenuButton
          selected={false}
          onClick={() => addItemBlock({ identity: { type: 'provider', family: 'provider', variant: 'genially' } })}
        >
          <img src={Genially} alt='Genially' /> genially
        </AsideMenuButton>
      </CollectionsMenu>
      {// Submenus dependiendo de la opcion elegida
      !isShowBlockTemplates() ? (
        <VariantsMenu isOpen={isOpen} showBlockTemplates={isShowBlockTemplates()}>
          {collection === -1
            ? []
            : blockList[collection].blocks.map(itemBlock => (
                <ItemBlockPreview
                  key={itemBlock.caption}
                  title={itemBlock.caption}
                  onClick={() => addItemBlock(itemBlock)}
                >
                  <img src={itemBlock.src} alt={itemBlock.caption} />
                </ItemBlockPreview>
              ))}
        </VariantsMenu>
      ) : (
        isOpen && (
          <BlockTemplatesMenu
            onClickNewTemplateBlock={_onClickNewTemplateBlock}
            onClickTemplateBlock={_onClickTemplateBlock}
          />
        )
      )}
      <div
        className={`create-block-sidebar__background ${isOpen && 'create-block-sidebar__background--opened'}`}
        onClick={() => closeMenu()}
      />
    </div>
  );
};

export default CreateBlockSidebar;
