import FroalaEditor from 'froala-editor';
(function () {  
  const FE = FroalaEditor;
  // FE = FE && FE.hasOwnProperty('default') ? FE['default'] : FE;

  /**
   * Euskera
   */
  FE.LANGUAGE.eu = {
    translation: {
      // Place holder
      'Type something': 'Idatzi zerbait',
      // Basic formatting
      Bold: 'Letra lodia',
      Italic: 'It\xE1lica',
      Underline: 'Azpimarratua',
      Strikethrough: 'Ezabatua',
      // Main buttons
      Insert: 'Txertatu',
      Delete: 'Ezabatu',
      Cancel: 'Utzi',
      OK: 'Ok',
      Back: 'Atzera',
      Remove: 'Kendu',
      More: 'Gehiago',
      Update: 'Eguneratu',
      Style: 'Estiloa',
      // Font
      'Font Family': 'Letra familia',
      'Font Size': 'Letraren neurria',
      // Colors
      Colors: 'Koloreak',
      Background: 'Atzealdea',
      Text: 'Testua',
      'HEX Color': 'Kolore hamaseitarra',
      // Paragraphs
      'Paragraph Format': 'Paragrafo formatua',
      Normal: 'Normala',
      Code: 'Kodea',
      'Heading 1': '1. goiburua',
      'Heading 2': '2. goiburua',
      'Heading 3': '3. goiburua',
      'Heading 4': '4. goiburua',
      'Line Height': 'Lerroartea',
      // Style
      'Paragraph Style': 'Paragrafo estiloa',
      'Inline Style': 'Lerro estiloa',
      // Alignment
      Align: 'Lerrokatu',
      'Align Left': 'Lerrokatu ezkerrean',
      'Align Center': 'Lerrokatu erdian',
      'Align Right': 'Lerrokatu eskuinean',
      'Align Justify': 'Justifikatu',
      None: 'Batere ez',
      // Lists
      'Ordered List': 'Zerrenda ordenatua',
      'Unordered List': 'Zerrenda desordenatua',
      // Indent
      'Decrease Indent': 'Txikitu koska',
      'Increase Indent': 'Handitu koska',
      // Links
      'Insert Link': 'Txertatu esteka',
      'Open in new tab': 'Ireki beste erlaitz batean',
      'Open Link': 'Ireki esteka',
      'Edit Link': 'Editatu esteka',
      Unlink: 'Kendu esteka',
      'Choose Link': 'Aukeratu esteka',
      // Images
      'Insert Image': 'Txertatu irudia',
      'Upload Image': 'Kargatu irudia',
      'By URL': 'URL bidez',
      Browse: 'Arakatu',
      'Drop image': 'Askatu irudia',
      'or click': 'edo egin klik hemen:',
      'Manage Images': 'Administratu irudiak',
      Loading: 'Kargatzen',
      Deleting: 'Ezabatu',
      Tags: 'Etiketak',
      'Are you sure? Image will be deleted.': 'Ziur zaude? Irudia ezabatu egingo da.',
      Replace: 'Ordeztu',
      Uploading: 'Kargatu',
      'Loading image': 'Irudia kargatzen',
      Display: 'Erakutsi',
      Inline: 'Lerroan',
      'Break Text': 'Testu jauzia',
      'Alternative Text': 'Testu alternatiboa',
      'Change Size': 'Aldatu neurria',
      Width: 'Zabalera',
      Height: 'Altuera',
      'Something went wrong. Please try again.': 'Zerbait gaizki atera da. Mesedez, saiatu berriz.',
      'Image Caption': 'Pantaila irudia',
      'Advanced Edit': 'Edizio aurreratua',
      // Video
      'Insert Video': 'Txertatu bideoa',
      'Embedded Code': 'Kodea txertatuta',
      'Drop video': 'Askatu bideoa',
      'Your browser does not support HTML5 video.': 'Zure nabigatzailea ez da bateragarria html5 bideoarekin.',
      'Upload Video': 'Igo bideoa',
      // Tables
      'Insert Table': 'Txertatu taula',
      'Table Header': 'Taularen goiburua',
      'Remove Table': 'Kendu taula',
      'Table Style': 'Taula estiloa',
      'Horizontal Align': 'Lerrokatze horizontala',
      Row: 'Fila',
      'Insert row above': 'Txertatu errenkada aurretik',
      'Insert row below': 'Txertatu errenkada ondoren',
      'Delete row': 'Ezabatu errenkada',
      Column: 'Zutabea',
      'Insert column before': 'Txertatu zutabea aurretik',
      'Insert column after': 'Txertatu errenkada ondoren',
      'Delete column': 'Ezabatu zutabea',
      Cell: 'Gelaxka',
      'Merge cells': 'Konbinatu gelaxkak',
      'Horizontal split': 'Zatiketa horizontala',
      'Vertical split': 'Zatiketa bertikala',
      'Cell Background': 'Gelaxkaren atzealdea',
      'Vertical Align': 'Lerrokatze bertikala',
      Top: 'Goi aldea',
      Middle: 'Erdi aldea',
      Bottom: 'Behe aldea',
      'Align Top': 'Lerrokatu goiko aldean',
      'Align Middle': 'Lerrokatu erdian',
      'Align Bottom': 'Lerrokatu behean',
      'Cell Style': 'Gelaxka estiloa',
      // Files
      'Upload File': 'Igo fitxategia',
      'Drop file': 'Askatu fitxategia',
      // Emoticons
      Emoticons: 'Emotikonoak',
      'Grinning face': 'Aurpegia irribarrez',
      'Grinning face with smiling eyes': 'Aurpegia irribarrez, begi irribarretsuekin',
      'Face with tears of joy': 'Aurpegia pozaren pozez malkotan',
      'Smiling face with open mouth': 'Aurpegi irribarretsua, ahoa irekita',
      'Smiling face with open mouth and smiling eyes': 'Aurpegi irribarretsua, ahoa irekita eta begi irribarretsuekin',
      'Smiling face with open mouth and cold sweat': 'Aurpegi irribarretsua, ahoa irekita eta izerdi hotzarekin',
      'Smiling face with open mouth and tightly-closed eyes':
        'Aurpegi irribarretsua, ahoa irekita eta begiak indarrez itxita',
      'Smiling face with halo': 'Aurpegi irribarretsua, argi koroarekin',
      'Smiling face with horns': 'Aurpegi irribarretsua, adarrekin',
      'Winking face': 'Aurpegiko keinua',
      'Smiling face with smiling eyes': 'Aurpegi irribarretsua, begi irribarretsuekin',
      'Face savoring delicious food': 'Janari gozagarria dastatzearen aurpegia',
      'Relieved face': 'Lasaitasun aurpegia',
      'Smiling face with heart-shaped eyes': 'Aurpegi irribarretsua, bihotz formako begiekin',
      'Smiling face with sunglasses': 'Aurpegi irribarretsua, eguzkitako betaurrekoekin',
      'Smirking face': 'Irribarre maltzurreko aurpegia',
      'Neutral face': 'Aurpegi neutrala',
      'Expressionless face': 'Espresiorik gabeko aurpegia',
      'Unamused face': 'Aspertu aurpegia',
      'Face with cold sweat': 'Aurpegia, izerdi hotzarekin',
      'Pensive face': 'Aurpegi pentsakorra',
      'Confused face': 'Aurpegi zalantzatia',
      'Confounded face': 'Aurpegi nahasia',
      'Kissing face': 'Aurpegia musu ematen',
      'Face throwing a kiss': 'Aurpegia musua botatzen',
      'Kissing face with smiling eyes': 'Aurpegia musua ematen, begi irribarretsuekin',
      'Kissing face with closed eyes': 'Aurpegia musua ematen, begiak itxita',
      'Face with stuck out tongue': 'Aurpegia mihia aterata',
      'Face with stuck out tongue and winking eye': 'Aurpegia mihia aterata eta begiarekin keinu egiten',
      'Face with stuck out tongue and tightly-closed eyes': 'Aurpegi mihia aterata eta begiak indarrez itxita',
      'Disappointed face': 'Desilusio aurpegia',
      'Worried face': 'Kezka aurpegia',
      'Angry face': 'Aurpegi haserretua',
      'Pouting face': 'Aurpegi txarra jarrita',
      'Crying face': 'Aurpegia negarrez',
      'Persevering face': 'Pertseberantzia aurpegia',
      'Face with look of triumph': 'Garaipen espresioa duen aurpegia',
      'Disappointed but relieved face': 'Desilusionatuta, baina aurpegia arinduta',
      'Frowning face with open mouth': 'Aurpegia, ahoa irekita eta bekozkoarekin',
      'Anguished face': 'Aurpegi larritua',
      'Fearful face': 'Aurpegi beldurtua',
      'Weary face': 'Aurpegi nekatua',
      'Sleepy face': 'Aurpegi logaletua',
      'Tired face': 'Aurpegi nekatua',
      'Grimacing face': 'Aurpegia keinu batekin',
      'Loudly crying face': 'Aurpegia ahots ozenez negarrez',
      'Face with open mouth': 'Aurpegia ahoa irekita',
      'Hushed face': 'Aurpegi isila',
      'Face with open mouth and cold sweat': 'Aurpegia ahoa irekita eta izerdi hotzarekin',
      'Face screaming in fear': 'Aurpegia beldurrez oihuka',
      'Astonished face': 'Aurpegi txunditua',
      'Flushed face': 'Aurpegi gorritua',
      'Sleeping face': 'Aurpegi lokartua',
      'Dizzy face': 'Aurpegi zorabiatua',
      'Face without mouth': 'Ahorik gabeko aurpegia',
      'Face with medical mask': 'Musuko medikoa duen aurpegia',
      // Line breaker
      Break: 'Etena',
      // Math
      Subscript: 'Azpiindizea',
      Superscript: 'Goi indizea',
      // Full screen
      Fullscreen: 'Pantaila osoa',
      // Horizontal line
      'Insert Horizontal Line': 'Txertatu lerro horizontala',
      // Clear formatting
      'Clear Formatting': 'Kendu formatua',
      // Undo, redo
      Undo: 'Desegin',
      Redo: 'Berregin',
      // Select all
      'Select All': 'Aukeratu dena',
      // Code view
      'Code View': 'Kode ikuspegia',
      // Quote
      Quote: 'Aipua',
      Increase: 'Handitu',
      Decrease: 'Murriztu',
      // Quick Insert
      'Quick Insert': 'Txertaketa bizkorra',
      // Spcial Characters
      'Special Characters': 'Karaktere bereziak',
      Latin: 'Latina',
      Greek: 'Greziera',
      Cyrillic: 'Zirilikoa',
      Punctuation: 'Puntuazioa',
      Currency: 'Moneta',
      Arrows: 'Geziak',
      Math: 'Mate',
      Misc: 'Nahas-mahasa',
      // Print.
      Print: 'Inprimaketa',
      // Spell Checker.
      'Spell Checker': 'Zuzentzaile ortografikoa',
      // Help
      Help: 'Laguntza',
      Shortcuts: 'Lasterbideak',
      'Inline Editor': 'Online editorea',
      'Show the editor': 'Erakutsi editorea',
      'Common actions': 'Ohiko ekintzak',
      Copy: 'Kopiatu',
      Cut: 'Ebaki',
      Paste: 'Itsatsi',
      'Basic Formatting': 'Oinarrizko formatua',
      'Increase quote level': 'Kotizazio maila igo',
      'Decrease quote level': 'Kotizazio maila jaitsi',
      'Image / Video': 'Irudia / bideoa',
      'Resize larger': 'Birdimentsionatu handiena',
      'Resize smaller': 'Birdimentsionatu txikiena',
      Table: 'Taula',
      'Select table cell': 'Aukeratu taulako gelaxka',
      'Extend selection one cell': 'Zabaldu gelaxka baten hautapena',
      'Extend selection one row': 'Zabaldu errenkada baten hautapena',
      Navigation: 'Nabigazioa',
      'Focus popup / toolbar': 'Focus popup / toolbar',
      'Return focus to previous position': 'Itzuli aurreko posiziora',
      // Embed.ly
      'Embed URL': 'Txertatutako URLa',
      'Paste in a URL to embed': 'Itsatsi txertatu beharreko URL batean',
      // Word Paste.
      'The pasted content is coming from a Microsoft Word document. Do you want to keep the format or clean it up?':
        'Itsatsitako edukia Microsoft Word dokumentu batetik dator. Formatua gorde nahi duzu edo garbitu egin nahi duzu?',
      Keep: 'Gorde',
      Clean: 'Garbitu',
      'Word Paste Detected': 'Hitza detektatuta',
      'Dashed Borders': 'Ertz etenak',
      'Alternate Rows': 'Errenkada alternatiboak',
      Highlighted: 'Nabarmendua',
      Thick: 'Lodia',
      'Paste the URL of the video you want to insert.':
        'Itsatsi txertatu nahi duzun bideoaren URLa. Bateragarria: Vimeo eta Youtube.',
      'Paste in a video URL': 'Bideoaren URLa (Vimeo edo Youtube).',
      'Number Group': 'Hausturarik gabe',
      'More Text': 'Testu gehiago',
      'Text Color': 'Testuaren kolorea',
      'Background Color': 'Atzealdeko kolorea',
      'More Paragraph': 'Paragrafo gehiago',
      'More Rich': 'Aukera gehiago',

      'More Misc': 'Aukera gehiago',
      'Add blank': 'Gehitu hutsunea',
      'Insert math expresion': 'Sartu adierazpen matematiko bat',
      'Font size': 'Testuaren tamaina',
      Default: 'Lehenetsia',
      'Lower Alpha': 'Alpha minuskula',
      'Lower Greek': 'Grekoa minuskula',
      'Lower Roman': 'Erromatarra minuskula ',
      'Upper Alpha': 'Alpha hizki larriak',
      'Upper Roman': 'Erromatarra hizki larriak',
      Circle: 'Zirculu',
      Square: 'Karratu',
      Disc: 'Diskoa',
      'With Borders': 'Mugak',
      'Insert Audio': 'Gehitu audioa',
      'Upload Audio': 'Erantsi audioa',
      'Only icon play/stop': 'Erreproduzitu/gelditu ikonoa soilik',
      'Drop audio': 'Erantsi audioa',
      'Paste in an audio URL': 'Itsatsi audio-url bat'
    },
    direction: 'ltr',
  };
})();
// # sourceMappingURL=es.js.map
