import PropTypes from 'prop-types';
import React from 'react';

/**
 * Componente que te avisa si haces click fuera de el
 */
export default class OutsideAlerter extends React.Component {
  constructor(props) {
    super(props);

    this.setWrapperRef = this.setWrapperRef.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
  }

  componentDidMount() {
    if (this.props.isActive) document.addEventListener('mousedown', this.handleClickOutside);
  }

  componentWillUnmount() {
    if (this.props.isActive) document.removeEventListener('mousedown', this.handleClickOutside);
  }

  /**
   * Set the wrapper ref
   */
  setWrapperRef(node) {
    this.wrapperRef = node;
  }

  /**
   * Avisa si se hace click fuera del elemento
   */
  handleClickOutside(event) {
    if (!this.isIgnoreEvent(event)) {
      if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
        if (this.props.isActive && this.props.handleClickOutside) {
          this.props.handleClickOutside();
        }
      }
    }
  }

  /**
   * Nos devuelve true si ignoramos este evento
   * @fuente https://stackoverflow.com/questions/39245488/event-path-is-undefined-running-in-firefox
   * @param event
   */
  isIgnoreEvent(event) {
    let ignore = false;
    let path;
    if (event) {
      path = event.path || (event.composedPath && event.composedPath());
    }
    if (path && this.props.classListIgnoreClick) {
      let found = false;
      // == Recorro la lista de path va es un listado de elementos del lugar pulsado y si dentro existe alguna de las clases a ignorar ignoro este evento
      const father = this;
      // == some devuelve true tan pronto como cualquiera de las devoluciones de llamada, ejecutadas en orden de matriz, devuelva true, cortocircuitando la ejecución del resto
      path.some(function (element) {
        if (element && element.classList) {
          father.props.classListIgnoreClick.some(function (classIgnore) {
            if (classIgnore && classIgnore.length > 0 && element.classList.contains(classIgnore)) {
              found = true;
              return true; // Devuelvo true para que se pare el bucle some
            } else return false;
          });
          if (found) {
            return true;
          } else return false;
        } else {
          return false;
        }
      });
      if (found) ignore = true;
    }
    return ignore;
  }

  render() {
    return (
      <div className='outside-alerter' ref={this.setWrapperRef}>
        {this.props.children}
      </div>
    );
  }
}

OutsideAlerter.propTypes = {
  children: PropTypes.element.isRequired,
  isActive: PropTypes.bool, // Si es true se activa el que te avise cuando se hace click fuera,
  classListIgnoreClick: PropTypes.array, // Es un array de string con el listado de clases a ignorar si hacen click en ella ejemplo ["lesson__block-interactive-label-link"]
};
