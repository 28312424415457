import FroalaEditor from 'froala-editor';
(function () {
  const FE = FroalaEditor;
  // FE = FE && FE.hasOwnProperty('default') ? FE['default'] : FE;

  /**
   * Spanish
   */
  if (FE && FE.LANGUAGE && FE.LANGUAGE.es) {
    FE.LANGUAGE.es.translation['Type something'] = 'Escribir aquí';
    FE.LANGUAGE.es.translation.Default = 'Por defecto';
    FE.LANGUAGE.es.translation.Circle = 'Círculo';
    FE.LANGUAGE.es.translation.Disc = 'Disco';
    FE.LANGUAGE.es.translation.OK = 'OK';
    FE.LANGUAGE.es.translation['Something went wrong. Please try again.'] =
      'Ha habido un problema. Por favor, vuelve a intentarlo.';
    FE.LANGUAGE.es.translation['Insert Video'] = 'Insertar vídeo';
    FE.LANGUAGE.es.translation['Paste in a video URL'] = 'Pegar una URL de un vídeo';
    FE.LANGUAGE.es.translation['Drop video'] = 'Soltar vídeo';
    FE.LANGUAGE.es.translation['Your browser does not support HTML5 video.'] =
      'Su navegador no es compatible con vídeos HTML5.';
    FE.LANGUAGE.es.translation['Upload Video'] = 'Subir vídeo';
    FE.LANGUAGE.es.translation['Remove Table'] = 'Borra la tabla';
    FE.LANGUAGE.es.translation['Relieved face'] = 'Cara aliviada';
    FE.LANGUAGE.es.translation.Math = 'Matemáticas';
    FE.LANGUAGE.es.translation.Misc = 'Miscelánea';
    FE.LANGUAGE.es.translation['Image / Video'] = 'Imagen / vídeo';
    FE.LANGUAGE.es.translation.Table = 'Tabla';
    FE.LANGUAGE.es.translation['Select table cell'] = 'Celda de tabla seleccionada';
    FE.LANGUAGE.es.translation['Focus popup / toolbar'] = 'Foco / barra de herramientas';
    FE.LANGUAGE.es.translation['Return focus to previous position'] = 'Volver el foco a la posición anterior';
    FE.LANGUAGE.es.translation['Embed URL'] = 'URL insertada';
    FE.LANGUAGE.es.translation['Paste in a URL to embed'] = 'Pegar una URL para insertar';

    FE.LANGUAGE.es.translation['Dashed Borders'] = 'Bordes discontinuos';
    FE.LANGUAGE.es.translation['Alternate Rows'] = 'Filas alternativas';
    FE.LANGUAGE.es.translation.Highlighted = 'Destacada';
    FE.LANGUAGE.es.translation.Thick = 'Gruesa';
    FE.LANGUAGE.es.translation['Paste the URL of the video you want to insert.'] =
      'Pegar URL del vídeo que quieres insertar. Compatible: Vimeo y Youtube.';
    FE.LANGUAGE.es.translation['Paste in a video URL'] = 'URL del vídeo (Vimeo o Youtube).';
    FE.LANGUAGE.es.translation['Number Group'] = 'No romper';
    FE.LANGUAGE.es.translation['More Text'] = 'Más texto';
    FE.LANGUAGE.es.translation['Text Color'] = 'Color texto';
    FE.LANGUAGE.es.translation['Background Color'] = 'Color fondo';
    FE.LANGUAGE.es.translation['More Paragraph'] = 'Más párrafo';
    FE.LANGUAGE.es.translation['More Rich'] = 'Más opciones';
    FE.LANGUAGE.es.translation['More Misc'] = 'Más opciones';
    FE.LANGUAGE.es.translation['Insert math expresion'] = 'Insertar expresión matemática';
    FE.LANGUAGE.es.translation['With Borders'] = 'Con bordes';
  }
})();
// # sourceMappingURL=es.js.map
