import PropTypes from 'prop-types';
import React from 'react';
import { getSettingsTab } from '../../../../tabsSidebar/TabsContentSettingsSidebarDefault/TabsContentSettingsSidebarDefault';
import TabSettingsSidebar, { SETTING_IMAGE_WIDTH } from '../../../../tabsSidebar/TabSettingsSidebar/TabSettingsSidebar';

const LabeledgraphicsSettings = ({
  item,
  onChangeItem,
  backgroundColor,
  paddingTop,
  paddingBottom,
  teacherOnly,
  t,
}) => (
  <div className='children-settings'>
    <section className={'mint-tab-content-settings-sidebar'}>
      <TabSettingsSidebar
        item={item}
        onChangeItem={onChangeItem}
        backgroundColor={backgroundColor}
        paddingTop={paddingTop}
        paddingBottom={paddingBottom}
        isTeacherOnly={teacherOnly}
        listSettings={getSettingsTab([SETTING_IMAGE_WIDTH])}
      ></TabSettingsSidebar>
    </section>
  </div>
);

LabeledgraphicsSettings.propTypes = {
  backgroundColor: PropTypes.string.isRequired,
};

export default LabeledgraphicsSettings;
