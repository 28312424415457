import React from 'react';
import posed, { PoseGroup } from 'react-pose';

const Container = posed.div({
  exit: { x: -660, transition: { duration: 200, ease: 'circIn' } },
  enter: { x: 0, delayChildren: 150, staggerChildren: 50, transition: { duration: 350, ease: 'circOut' } },
});

const Item = posed.li({
  exit: { x: -50, y: -80, rotate: 15, opacity: 0 },
  enter: {
    x: 0,
    y: 0,
    rotate: 0,
    opacity: 1,
    transition: {
      default: { duration: 450, type: 'spring', stiffness: 60 },
    },
  },
});

const VariantsMenu = ({ isOpen, children }) => {
  return (
    <Container className='variants-menu' pose={children.length > 0 ? 'enter' : 'exit'}>
      {isOpen && (
        <ul className='variants-menu__list-container'>
          <PoseGroup>
            {children.map((Link, i) => (
              <Item i={i} className='variants-menu__list-item' key={`itemblock__${Link.key}`}>
                {Link}
              </Item>
            ))}
          </PoseGroup>
        </ul>
      )}
    </Container>
  );
};

export default VariantsMenu;
