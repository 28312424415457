import { EDIT_TYPE_GROUPS, PROPERTY_EDIT_TYPE_GROUP } from './editTypeBlock.services';
// TODO esta configuracion que venga del host, hacer que blocklist sea una funcion en lugar de una constante y que tenga como parametros la configuracion
const LEMONADE_ENABLED = false; // TODO nose si lo vamos a usar en mint compoment
const LEMONADE_CONTENT_GUID = true; // TODO esta configuracion que venga del host
export const blockList = {
  types: [
    {
      name: 'text',
      families: [
        {
          name: 'text',
          variants: [
            {
              name: 'Paragraph',
              key: 'paragraph',
              image: 'text_paragraph@2x.png',
              [PROPERTY_EDIT_TYPE_GROUP]: EDIT_TYPE_GROUPS.EDIT_TYPE_GROUP_TEXTS,
            },
            {
              name: 'Paragraph with Heading',
              key: 'heading paragraph',
              image: 'text_paragraph_heading@2x.png',
              [PROPERTY_EDIT_TYPE_GROUP]: EDIT_TYPE_GROUPS.EDIT_TYPE_GROUP_TEXTS,
            },
            {
              name: 'Paragraph with Subheading',
              key: 'subheading paragraph',
              image: 'text_paragraph_subheading@2x.png',
              [PROPERTY_EDIT_TYPE_GROUP]: EDIT_TYPE_GROUPS.EDIT_TYPE_GROUP_TEXTS,
            },
            {
              name: 'Heading',
              key: 'heading',
              image: 'text_heading@2x.png',
              [PROPERTY_EDIT_TYPE_GROUP]: EDIT_TYPE_GROUPS.EDIT_TYPE_GROUP_TEXTS,
              settings: {
                paddingBottom: 0,
              },
            },
            {
              name: 'Subheading',
              key: 'subheading',
              image: 'text_subheading@2x.png',
              [PROPERTY_EDIT_TYPE_GROUP]: EDIT_TYPE_GROUPS.EDIT_TYPE_GROUP_TEXTS,
              settings: {
                paddingBottom: 0,
              },
            },
            {
              name: 'Two column',
              key: 'two column',
              image: 'text_2column@2x.png',
              [PROPERTY_EDIT_TYPE_GROUP]: EDIT_TYPE_GROUPS.EDIT_TYPE_GROUP_TEXTS,
            },
          ],
          placeholder: 'textPlaceholder',
        },
        {
          name: 'impact',
          variants: [
            /*  {
              name: 'Statement A',
              key: 'a',
              image: 'statement_A@2x.png',
              [PROPERTY_EDIT_TYPE_GROUP]: EDIT_TYPE_GROUPS.EDIT_TYPE_GROUP_TEXTS,
            },
            {
              name: 'Statement B',
              key: 'b',
              image: 'statement_B@2x.png',
              [PROPERTY_EDIT_TYPE_GROUP]: EDIT_TYPE_GROUPS.EDIT_TYPE_GROUP_TEXTS,
            },
            {
              name: 'Statement C',
              key: 'c',
              image: 'statement_C@2x.png',
              [PROPERTY_EDIT_TYPE_GROUP]: EDIT_TYPE_GROUPS.EDIT_TYPE_GROUP_TEXTS,
            },
            {
              name: 'Statement D',
              key: 'd',
              image: 'statement_D@2x.png',
              [PROPERTY_EDIT_TYPE_GROUP]: EDIT_TYPE_GROUPS.EDIT_TYPE_GROUP_TEXTS,
            },*/
            {
              name: 'Teacher notes',
              key: 'teacher notes',
              image: 'statement_teacher_notes@2x.png',
              [PROPERTY_EDIT_TYPE_GROUP]: EDIT_TYPE_GROUPS.EDIT_TYPE_GROUP_TEXTS,
            },
            {
              name: 'Note',
              key: 'note',
              image: 'statement_note@2x.png',
              [PROPERTY_EDIT_TYPE_GROUP]: EDIT_TYPE_GROUPS.EDIT_TYPE_GROUP_TEXTS,
            },
            {
              name: 'Statement A',
              key: 'statementA',
              image: 'statement_A@2x.png',
              [PROPERTY_EDIT_TYPE_GROUP]: EDIT_TYPE_GROUPS.EDIT_TYPE_GROUP_TEXTS,
            },
            {
              name: 'Statement D',
              key: 'statementD',
              image: 'statement_D@2x.png',
              [PROPERTY_EDIT_TYPE_GROUP]: EDIT_TYPE_GROUPS.EDIT_TYPE_GROUP_TEXTS,
            },
          ],
          placeholder: 'impactPlaceholder',
        },
      ],
    },
    {
      name: 'quote',
      families: [
        {
          name: 'quote',
          variants: [
            {
              name: 'Quote A',
              key: 'a',
              image: 'quote_A@2x.png',
              [PROPERTY_EDIT_TYPE_GROUP]: EDIT_TYPE_GROUPS.EDIT_TYPE_GROUP_QUOTES,
            },
            /*{
              name: 'Quote B',
              key: 'b',
              image: 'quote_B@2x.png',
              [PROPERTY_EDIT_TYPE_GROUP]: EDIT_TYPE_GROUPS.EDIT_TYPE_GROUP_QUOTES,
            },
            {
              name: 'Quote C',
              key: 'c',
              image: 'quote_C@2x.png',
              [PROPERTY_EDIT_TYPE_GROUP]: EDIT_TYPE_GROUPS.EDIT_TYPE_GROUP_QUOTES,
            },
            {
              name: 'Quote D',
              key: 'd',
              image: 'quote_D@2x.png',
              [PROPERTY_EDIT_TYPE_GROUP]: EDIT_TYPE_GROUPS.EDIT_TYPE_GROUP_QUOTES,
            },
            {
              name: 'Quote on image',
              key: 'background',
              image: 'quote_on_image@2x.png',
              [PROPERTY_EDIT_TYPE_GROUP]: EDIT_TYPE_GROUPS.EDIT_TYPE_GROUP_QUOTES,
            },
            {
              name: 'Quote carousel',
              key: 'carousel',
              image: 'quote_carousel@2x.png',
              [PROPERTY_EDIT_TYPE_GROUP]: EDIT_TYPE_GROUPS.EDIT_TYPE_GROUP_QUOTES,
            },*/
          ],
          placeholder: 'quotePlaceholder',
        },
      ],
    },
    {
      name: 'list',
      families: [
        {
          name: 'list',
          variants: [
            {
              name: 'Numbered list',
              key: 'numbered',
              image: 'list_numbered@2x.png',
              [PROPERTY_EDIT_TYPE_GROUP]: EDIT_TYPE_GROUPS.EDIT_TYPE_GROUP_LISTS,
            },
            /*  {
              name: 'Checkbox list',
              key: 'checkboxes',
              image: 'list_checkbox@2x.png',
              [PROPERTY_EDIT_TYPE_GROUP]: EDIT_TYPE_GROUPS.EDIT_TYPE_GROUP_LISTS,
            }, */
            {
              name: 'Bulleted list',
              key: 'bulleted',
              image: 'list_bulleted@2x.png',
              [PROPERTY_EDIT_TYPE_GROUP]: EDIT_TYPE_GROUPS.EDIT_TYPE_GROUP_LISTS,
            },
          ],
          placeholder: 'listPlaceholder',
        },
      ],
    },
    {
      name: 'image',
      families: [
        {
          name: 'image',
          variants: [
            {
              name: 'Image centered',
              key: 'hero',
              image: 'image_centered@2x.png',
              [PROPERTY_EDIT_TYPE_GROUP]: EDIT_TYPE_GROUPS.EDIT_TYPE_GROUP_IMAGES,
            },
            {
              name: 'Image full width',
              key: 'full',
              image: 'image_full_width@2x.png',
              [PROPERTY_EDIT_TYPE_GROUP]: EDIT_TYPE_GROUPS.EDIT_TYPE_GROUP_IMAGES,
            },
            {
              name: 'Image & text',
              key: 'text aside',
              image: 'image_image_and_text@2x.png',
              [PROPERTY_EDIT_TYPE_GROUP]: EDIT_TYPE_GROUPS.EDIT_TYPE_GROUP_IMAGES,
            },
            {
              name: 'Text on image',
              key: 'text overlay',
              image: 'image_text_on_image@2x.png',
              [PROPERTY_EDIT_TYPE_GROUP]: EDIT_TYPE_GROUPS.EDIT_TYPE_GROUP_IMAGES,
            },
          ],
          settings: {
            zoomOnClick: true,
            opacity: 0.2,
            opacityColor: '#000000',
          },
          placeholder: 'imagePlaceholder',
        },
        {
          name: 'gallery',
          variants: [
            {
              name: 'Carousel',
              key: 'centered',
              image: 'gallery_carousel@2x.png',
              [PROPERTY_EDIT_TYPE_GROUP]: EDIT_TYPE_GROUPS.EDIT_TYPE_GROUP_IMAGES,
            },
            {
              name: 'Two column grid',
              key: 'two column',
              image: 'gallery_two_column_grid@2x.png',
              [PROPERTY_EDIT_TYPE_GROUP]: EDIT_TYPE_GROUPS.EDIT_TYPE_GROUP_IMAGES,
            },
            {
              name: 'Three column grid',
              key: 'three column',
              image: 'gallery_three_column_grid@2x.png',
              [PROPERTY_EDIT_TYPE_GROUP]: EDIT_TYPE_GROUPS.EDIT_TYPE_GROUP_IMAGES,
            },
            {
              name: 'Four column grid',
              key: 'four column',
              image: 'gallery_four_column_grid@2x.png',
              [PROPERTY_EDIT_TYPE_GROUP]: EDIT_TYPE_GROUPS.EDIT_TYPE_GROUP_IMAGES,
            },
          ],
          settings: {
            zoomOnClick: true,
          },
          placeholder: 'galleryPlaceholder',
        },
      ],
    },
    {
      name: 'multimedia',
      families: [
        {
          name: 'multimedia',
          variants: [
            {
              name: 'Audio',
              key: 'audio',
              image: 'multimedia_audio@2x.png',
            },
            {
              name: 'Video',
              key: 'video',
              image: 'multimedia_video@2x.png',
            },
            {
              name: 'Embed',
              key: 'embed',
              image: 'multimedia_embed_v2@2x.png',
            },
            /* {
              name: 'Attachment',
              key: 'attachment',
              image: 'multimedia_attachment@2x.png',
            },
            {
              name: 'Code snippet',
              key: 'code',
              image: 'text_code_snippet@2x.png',
            }, */
          ],
          placeholder: 'multimediaPlaceholder',
        },
      ],
    },
    {
      name: 'interactive',
      grouped: true,
      families: [
        {
          name: 'interactive',
          exclusive: true,
          variants: [
            {
              name: 'Accordion',
              key: 'accordion',
              image: 'interactive_accordion@2x.png',
              [PROPERTY_EDIT_TYPE_GROUP]: EDIT_TYPE_GROUPS.EDIT_TYPE_GROUP_TABS_AND_OTHER,
            },
            {
              name: 'Tabs',
              key: 'tabs',
              image: 'interactive_tabs@2x.png',
              [PROPERTY_EDIT_TYPE_GROUP]: EDIT_TYPE_GROUPS.EDIT_TYPE_GROUP_TABS_AND_OTHER,
            },
          ],
          settings: {
            zoomOnClick: true,
          },
          placeholder: 'interactivePlaceholder',
        },
        {
          name: 'interactive-fullscreen',
          exclusive: true,
          variants: [
            {
              name: 'Labeled Graphic',
              key: 'labeledgraphic',
              image: 'interactive_labeledgraphic@2x.png',
              settings: {
                backgroundColor: '#ffffff',
              },
              customEditor: true,
              showSettingsEditor: true,
            },
            {
              name: 'Slider',
              key: 'process',
              image: 'interactive_process@2x.png',
            },
            {
              name: 'Timeline',
              key: 'timeline',
              image: 'interactive_timeline@2x.png',
            },
          ],
          settings: {
            zoomOnClick: true,
            mediaWidth: '1',
            backgroundColor: '#f5f5f5',
          },
          placeholder: 'fullscreenInteractivePlaceholder',
          mediaPlaceholder: 'interactiveMediaPlaceholder',
        },
        {
          name: 'buttons',
          exclusive: true,
          variants: [
            {
              name: 'Button',
              key: 'button',
              image: 'interactive_text_button@2x.png',
              [PROPERTY_EDIT_TYPE_GROUP]: EDIT_TYPE_GROUPS.EDIT_TYPE_GROUP_BUTTONS,
            },
            /*{
              name: 'Button stack',
              key: 'button stack',
              image: 'interactive_text_button_stack@2x.png',
              [PROPERTY_EDIT_TYPE_GROUP]: EDIT_TYPE_GROUPS.EDIT_TYPE_GROUP_BUTTONS,
            },*/
          ],
          placeholder: 'interactivePlaceholder',
        },
      ],
    },
    {
      name: 'divider',
      families: [
        {
          name: 'divider',
          variants: [
            {
              name: 'Divider',
              key: 'divider',
              image: 'divider@2x.png',
              content: false,
              [PROPERTY_EDIT_TYPE_GROUP]: EDIT_TYPE_GROUPS.EDIT_TYPE_GROUP_DIVIDER_AND_OTHER,
            },
            {
              name: 'Spacer',
              key: 'spacing divider',
              image: 'spacer@2x.png',
              content: false,
              [PROPERTY_EDIT_TYPE_GROUP]: EDIT_TYPE_GROUPS.EDIT_TYPE_GROUP_DIVIDER_AND_OTHER,
            },
          ],
          placeholder: null,
        },
      ],
    },
    LEMONADE_CONTENT_GUID
      ? {
          name: 'lemonade',
          families: [
            {
              name: 'lemonade',
              variants: [
                {
                  name: 'Multiple choice standard',
                  key: 'Lemonade content guid',
                  customEditor: false,
                  image: 'lemonade_multiple_choice_single.png',
                },
              ],
            },
          ],
        }
      : undefined,
    LEMONADE_ENABLED
      ? {
          name: 'lemonade',
          families: [
            {
              name: 'lemonade',
              variants: [
                {
                  name: 'Multiple choice standard',
                  key: 'Multiple choice – standard',
                  customEditor: true,
                  image: 'lemonade_multiple_choice_single.png',
                },
                {
                  name: 'Multiple choice multiple',
                  key: 'Multiple choice – multiple response',
                  customEditor: true,
                  image: 'lemonade_multiple_choice_standard.png',
                },
                {
                  name: 'Multiple choice true / false',
                  key: 'True or false',
                  customEditor: true,
                  image: 'lemonade_multiple_choice_true_false.png',
                },
                {
                  name: 'Multiple choice block letter',
                  key: 'Multiple choice – block layout',
                  customEditor: true,
                  image: 'lemonade_multiple_choice_letter.png',
                },
                {
                  name: 'Choice matrix standard',
                  key: 'Choice matrix – standard',
                  customEditor: true,
                  image: 'lemonade_choice_matrix_standard.png',
                },
                {
                  name: 'Choice matrix inline',
                  key: 'Choice matrix – inline',
                  customEditor: true,
                  image: 'lemonade_choice_matrix_inline.png',
                },
                {
                  name: 'Essay plain text',
                  key: 'Essay with plain text',
                  customEditor: true,
                  image: 'lemonade_essay_plain_text.png',
                },
                {
                  name: 'Essay short text',
                  key: 'Short text',
                  customEditor: true,
                  image: 'lemonade_essay_short_text.png',
                },
                {
                  name: 'Cloze with drag & drop',
                  key: 'Cloze with drag & drop',
                  customEditor: true,
                  image: 'lemonade_cloze_drag_drop.png',
                },
                {
                  name: 'Cloze with dropdown',
                  key: 'Cloze with drop down',
                  customEditor: true,
                  image: 'lemonade_cloze_dropdown.png',
                },
                {
                  name: 'Cloze with text',
                  key: 'Cloze with text',
                  customEditor: true,
                  image: 'lemonade_cloze_text.png',
                },
                {
                  name: 'Classification',
                  key: 'Classification',
                  customEditor: true,
                  image: 'lemonade_classify_classification.png',
                },
                {
                  name: 'Match list',
                  key: 'Match list',
                  customEditor: true,
                  image: 'lemonade_classify_match_list.png',
                },
                {
                  name: 'Order list',
                  key: 'Order list',
                  customEditor: true,
                  image: 'lemonade_classify_order_list.png',
                },
                {
                  name: 'Label image with text',
                  key: 'LabelImageTextV2',
                  customEditor: true,
                  image: 'lemonade_label_image_text.png',
                },
              ],
            },
          ],
        }
      : undefined,
    /* {
      name: 'blockTemplates',
      families: [
        {
          name: 'blockTemplates',
          variants: [],
        },
      ],
    }, */
    {
      name: 'provider',
      hideInsideMenuCreate: true,
      families: [
        {
          name: 'provider',
          variants: [
            {
              name: 'Edpuzzle',
              key: 'edpuzzle',
              image: 'interactive_process@2x.png',
            },
            {
              name: 'Genially',
              key: 'genially',
              image: 'interactive_process@2x.png',
            },
          ],
        },
      ],
    },
    {
      name: 'pending',
      hideInsideMenuCreate: true,
      families: [
        {
          name: 'pending',
          variants: [
            {
              name: 'Pending',
              key: 'pending',
              hideControlsEditor: true,
            },
          ],
        },
      ],
    },
  ].filter(x => x !== undefined),
};
