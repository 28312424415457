function ignoreSomeWarning() {
  const backup = console.warn;
  console.warn = function filterWarnings(msg) {
    try {
      // == TODO: En un futuro intentar solventar estos warning de raiz
      const supressedWarnings = [
        '[MobX] Since strict-mode is enabled, changing (observed)',
        './src/_mint/services/readJsonData.services.ts', // == Critical dependency: the request of a dependency is an expression
      ];
      if (!supressedWarnings.some(entry => msg.includes(entry))) {
        console.log(msg);
        // @ts-ignore
        backup.apply(console, arguments);
      }
    } catch (error) {
      backup.apply(console, arguments);
    }
  };
}

function parse(str) {
  var args = [].slice.call(arguments, 1),
    i = 0;
  return str.replace(/%s/g, () => args[i++]);
}

function ignoreSomeError() {
  const backup = console.error;
  console.error = function filterErrors(msg) {
    try {
      // == TODO: En un futuro intentar solventar estos errores de raiz
      const supressedErrors = [
        'Warning: findDOMNode is deprecated in StrictMode. findDOMNode was passed an instance of VisibilitySensor',
        'Warning: Cannot update a component (`FileUploaderStore`) while rendering a different component (`LemonadeQuestion`)',
        'at ObservableObject@1470.instance.Question', // == index.js:1 Warning: Can't perform a React state update on an unmounted component. This is a no-op, but it indicates a memory leak in your application. To fix, cancel all subscriptions and asynchronous tasks in a useEffect cleanup function.
      ];
      const msgWithArguments = parse.apply(msg, arguments);
      if (!supressedErrors.some(entry => msgWithArguments.includes(entry))) {
        // @ts-ignore
        backup.apply(console, arguments);
      }
    } catch (error) {
      backup.apply(console, arguments);
    }
  };
}

export function ignoreSomeErrorAndWarning() {
  ignoreSomeWarning();
  ignoreSomeError();
}
