import React, { useEffect, useState } from 'react';
import posed from 'react-pose'; // TODO: sustituirlo porque dicen que ha sido deprecado https://www.npmjs.com/package/react-pose
import { ReactComponent as Delete } from '../../../../../../assets/icons/delete.svg';
import { ReactComponent as Edit } from '../../../../../../assets/icons/edit.svg';
import Button from '../../../../../../components/buttons/Button/Button';
import useGeneral from '../../../../../../hooks/useGeneral';
// import BlockTemplatesNamePopup from '../../../block-templates-top-actions/block-templates-name-popup';
import FilterDropdown from '../FilterDropdown/FilterDropdown';
import SearchInput from '../SearchInput/SearchInput';

// TODO: llevarlo a un fichero
const URL_BASE = (window.conf && window.conf.URL_BASE) || 'http://localhost:8081'; // "https://mint.oneclick.es";
const IMAGES_SRC_URI = URL_BASE + '/mintcontent'; // Rise path change

const Container = posed.div({
  exit: { x: -660, transition: { duration: 200, ease: 'circIn' } },
  enter: { x: 0, delayChildren: 150, staggerChildren: 50, transition: { duration: 350, ease: 'circOut' } },
});

const BlockTemplatesMenu = ({ onClickNewTemplateBlock, onClickTemplateBlock }) => {
  const { t } = useGeneral();
  const OPTIONS_SORT = {
    RECENT: 'RECENT',
    TITLE: 'TITLE',
  };
  const OPTIONS_AUTHOR = {
    ALL: 'ALL',
  };
  const AUTHOR_OPTION_ALL = {
    id: 0,
    title: t('All Authors'),
    selected: true,
    key: OPTIONS_AUTHOR.ALL,
  };
  // const dispatch = useDispatch();
  // const templates = useSelector((state) => getBlockTemplates(state));
  const [templatesFilter, setTemplatesFilter] = useState([]);
  const [authorFilter, setAuthorFilter] = useState(OPTIONS_AUTHOR.ALL);
  const [sortFilter, setSortFilter] = useState(OPTIONS_SORT.RECENT);
  const [search, setSearch] = useState('');
  const [authorList, setAuthorList] = useState([AUTHOR_OPTION_ALL]);
  const [showPopupEditName, setShowPopupEditName] = useState({
    isVisible: false,
    template: null,
  });

  // TODO: QUITADO
  /* 
  useEffect(() => {
    dispatch(sendAction(fetchBlockTemplates(null)));
  }, []); */

  // TODO: QUITADO
  /* useEffect(() => {
    setTemplatesFilter(getTemplatesFilter());
    setAuthorList(getAuthorList());
  }, [templates]); */

  useEffect(() => {
    setTemplatesFilter(getTemplatesFilter());
  }, [search, authorFilter, sortFilter]);

  function canShowTemplate(template) {
    const canShowBySearch = template.name.search(search) !== -1;
    const canShowByAuthor = authorFilter === OPTIONS_AUTHOR.ALL || authorFilter === template.author;
    return canShowBySearch && canShowByAuthor;
  }

  function getTemplatesFilter() {
    const toRet = [];
    /* if (templates) {
      toRet = templates.filter((template) => canShowTemplate(template));
      // == Lo ordeno
      if (sortFilter === OPTIONS_SORT.RECENT) {
        toRet.sort((a, b) =>
          new Date(a.createdAt).getTime() > new Date(b.createdAt).getTime()
            ? -1
            : new Date(b.createdAt).getTime() > new Date(a.createdAt).getTime()
            ? 1
            : 0
        );
      } else if (sortFilter === OPTIONS_SORT.TITLE) {
        toRet.sort((a, b) => a.name.localeCompare(b.name, undefined, { numeric: true, ignorePunctuation: true }));
      }
    } */
    // TODO: QUITADO
    return toRet;
  }

  function getAuthorList() {
    const toRet = [AUTHOR_OPTION_ALL];
    // TODO: QUITADO
    /* if (templates) {
      templates.map((template) => {
        if (toRet.findIndex((t) => t.key === template.profile.user_id) === -1) {
          if (template && template.profile) {
            toRet.push({
              id: template.profile.user_id,
              title: template.profile.first_name + ' ' + template.profile.last_name,
              selected: false,
              key: template.profile.user_id,
              icon: template.profile.avatars ? template.profile.avatars.mini : null,
            });
          }
        }
      });
    } */
    return toRet;
  }

  function onClickTemplate(template) {
    onClickTemplateBlock && onClickTemplateBlock(template);
  }

  const createTemplateBlock = () => {
    onClickNewTemplateBlock && onClickNewTemplateBlock();
  };

  const sortList = [
    {
      id: 0,
      title: t('More recent'),
      selected: true,
      key: OPTIONS_SORT.RECENT,
    },
    {
      id: 1,
      title: t('Title'),
      selected: false,
      key: OPTIONS_SORT.TITLE,
    },
  ];

  function getSearchValue(value) {
    setSearch(value);
  }

  function onChangeAuthorFilter(key) {
    setAuthorFilter(key);
  }

  function onChangeSortFilter(key) {
    setSortFilter(key);
  }

  function onEdit(e, template) {
    e.stopPropagation();
    e.preventDefault();
    setShowPopupEditName({
      isVisible: true,
      template: template,
    });
  }

  function onDelete(e, template) {
    e.stopPropagation();
    e.preventDefault();
    // TODO: QUITADO dispatch(sendAction(deleteBlockTemplates(template.id)));
  }

  function onCancelPopupEditName() {
    setShowPopupEditName({
      isVisible: false,
      template: null,
    });
  }

  function onSavePopupEditName(name, share) {
    // TODO: QUITADO
    /* dispatch(
      sendAction(
        updateBlockTemplates({
          ...showPopupEditName.template,
          name: name,
          shared: share,
        })
      )
    ); */
    setShowPopupEditName({
      isVisible: false,
      template: null,
    });
  }

  return (
    <Container className='block-templates-menu' pose={'enter'}>
      <div className='block-templates-menu__header'>
        <div className='block-templates-menu__search-input'>
          <SearchInput placeholder={t('Search') + '...'} text='' getValue={getSearchValue} />
        </div>

        <div className='block-templates-menu__filter'>
          <span className='block-templates-menu__filter-label'>{t('Author') + ':'}</span>
          <span className='block-templates-menu__filter-menu'>
            <FilterDropdown
              title={authorList.find((element) => element.selected).title}
              list={authorList}
              onChange={onChangeAuthorFilter}
            />
          </span>
        </div>

        <div className='block-templates-menu__filter'>
          <span className='block-templates-menu__filter-label'>{t('Sort by') + ':'}</span>
          <span className='block-templates-menu__filter-menu'>
            <FilterDropdown
              title={sortList.find((element) => element.selected).title}
              list={sortList}
              onChange={onChangeSortFilter}
            />
          </span>
        </div>
      </div>
      <div className='block-templates-menu__content'>
        <div className='block-template-list'>
          {templatesFilter.map((template) => (
            <div className='block-template-list__item' onClick={() => onClickTemplate(template)} key={template.id}>
              <div className='block-template-list__item-name'>{template.name}</div>

              <div className='block-template-list__item-image'>
                <img src={IMAGES_SRC_URI + '/' + template.thumbnailAssetKey} alt={template.name} />
              </div>
              <div className='block-template-list__actions'>
                <div className='block-template-list__actions-edit' onClick={(e) => onEdit(e, template)}>
                  <Edit />
                </div>
                <div className='block-template-list__actions-delete' onClick={(e) => onDelete(e, template)}>
                  <Delete />
                </div>
              </div>
            </div>
          ))}
          {templatesFilter.length === 0 && (
            <div className='block-template-menu__no-templates'>
              <div className='block-template-menu__no-templates-text'>{t('There are no template blocks')}</div>
              <div className='block-templates-menu__new-button'>
                <Button onClick={createTemplateBlock}>{t('New template')}</Button>
              </div>
            </div>
          )}

          {templatesFilter.length !== 0 && (
            <div className='block-templates-menu__new-button'>
              <Button onClick={createTemplateBlock}>{t('New template')}</Button>
            </div>
          )}
        </div>
      </div>
      {/* TODO QUITADO showPopupEditName && showPopupEditName.isVisible && (
        <BlockTemplatesNamePopup
          onCancel={onCancelPopupEditName}
          onSave={onSavePopupEditName}
          nameInit={showPopupEditName.template.name}
          shareInit={showPopupEditName.template.shared}
        />
      ) */}
    </Container>
  );
};

export default BlockTemplatesMenu;
