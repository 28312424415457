import React from 'react';

const ItemBlockPreview = (props) => {
  const { children, modifier = 'primary', title = '' } = props;
  return (
    <button className={`item-block-preview item-block-preview--${modifier}`} {...props}>
      {!!title && <h6 className='item-block-preview__title'>{title}</h6>}
      <div className='item-block-preview__img'>{children}</div>
    </button>
  );
};

export default ItemBlockPreview;
