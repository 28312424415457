/**
 * External dependencies
 */
import classNames from 'classnames';
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
/**
 * Internal dependencies
 */
import { ReactComponent as CloseIcon } from '../../assets/icons/close.svg';

export const MINT_ID_BLOCKS_SIDEBAR_CONTENT = 'mint-id-blocks-sidebar-content';
export const MINT_BLOCKS_SIDEBAR_CONTENT_KEYBOARD_VISIBLE = 'mint-blocks-sidebar__content---keyboard-visible';
export const MINT_BLOCKS_SIDEBAR_CONTENT_COLOR_PICKER_VISIBLE = 'mint-blocks-sidebar__content---color-picker-visible';

class FloatingSidebar extends Component {
  constructor(props) {
    super(props);
    this.el = document.createElement('div');
    this.container = props.container ? document.getElementById(props.container || '') || document.body : document.body;

    this.state = {
      visible: props.visible || false,
      mounted: false,
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState({
      visible: nextProps.visible || false,
    });
  }

  componentDidMount() {
    // The portal element is inserted in the DOM tree after
    // the Modal's children are mounted, meaning that children
    // will be mounted on a detached DOM node. If a child
    // component requires to be attached to the DOM tree
    // immediately when mounted, for example to measure a
    // DOM node, or uses 'autoFocus' in a descendant, add
    // state to Modal and only render the children when Modal
    // is inserted in the DOM tree.
    this.container.appendChild(this.el);
    setTimeout(() => this.setState({ mounted: true }), 100);
  }

  componentWillUnmount() {
    this.container.removeChild(this.el);
  }

  handleClose = event => {
    event.preventDefault();
    this.setState({ visible: false });
    try {
      this.props.onClose && setTimeout(() => this.props.onClose(), 450);
    } catch (e) {}
  };

  render() {
    const { title } = this.props;
    const sidebarClasses = classNames({
      'mint-blocks-sidebar': true,
      'mint-blocks-sidebar--open': this.state.visible && this.state.mounted,
    });
    const sidebarOverlayClasses = classNames({
      'mint-blocks-sidebar__overlay': true,
      'mint-blocks-sidebar__overlay--active': this.state.visible,
    });
    return ReactDOM.createPortal(
      <div>
        <div className={sidebarClasses}>
          <div className='mint-blocks-sidebar__container mint-blocks-sidebar__edit'>
            <div className='mint-blocks-sidebar__header'>
              <h6 className='mint-blocks-sidebar__title'>{title}</h6>
              <button className='mint-blocks-sidebar__close' onClick={this.handleClose}>
                <CloseIcon />
              </button>
            </div>
            <div className='block-edit'>
              <div className='block-edit__content' id={MINT_ID_BLOCKS_SIDEBAR_CONTENT}>
                {this.props.children}
              </div>
            </div>
          </div>
        </div>
        <div className={sidebarOverlayClasses} onClick={this.handleClose} />
      </div>,
      this.el
    );
  }
}

export default FloatingSidebar;
