import { useCallback, useEffect, useState } from 'react';

export const MATHLIVE_SCRIPT_ID = 'mathliveScriptId';
const mathLiveUrl = '//unpkg.com/mathlive@0.94.8/dist/mathlive.min.js';

const useMathLive = () => {
  const [mathLiveReady, setReady] = useState(false);
  const onLoadDone = useCallback(() => {
    // eslint-disable-next-line
    (function retry(times) {
      !window.MathLive ? setTimeout(() => retry(times - 1), 200) : setReady(true);
    })(2);
  }, [setReady]);

  const loadScript = useCallback(
    (url, id) => {
      if (!document.getElementById(id)) {
        const script = document.createElement('script');
        script.src = url;
        script.id = id;
        script.async = true;
        script.addEventListener('load', onLoadDone);
        document.body.appendChild(script);
      } else {
        onLoadDone();
      }
    },
    [onLoadDone]
  );

  useEffect(() => {
    loadScript(mathLiveUrl, MATHLIVE_SCRIPT_ID);
  }, []);

  return { mathLiveReady };
};
export default useMathLive;
