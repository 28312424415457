import React from 'react';
import EditOptions from '../../../../../../components/EditOptions/EditOptions';

export default function WrapperPostOptions({
  children,
  onUp,
  onDown,
  onDuplicate,
  onDelete,
  onEdit,
  onAudios,
  firstElement = false,
  lastElement = false,
  enablePostOptions = true,
  pdfPages,
  onChangePdfPages,
}) {
  return (
    <div className='wrapper-post-editor'>
      <div className={`wrapper-post-editor__controls-wrapper wrapper-post-editor__controls-wrapper--show`}>
        <div className='wrapper-post-editor__left-controls'>
          {enablePostOptions && (
            <EditOptions
              onUp={onUp}
              onDown={onDown}
              onDuplicate={onDuplicate}
              onDelete={onDelete}
              onAudios={onAudios}
              upEnable={!firstElement}
              downEnable={!lastElement}
              isSecondary={false}
              pdfPages={pdfPages}
              onChangePdfPages={onChangePdfPages}
            />
          )}
        </div>
        <div className='wrapper-post-editor__right-controls'>{/* <Button onClick={onEdit}>Edit</Button> */}</div>
      </div>
      {children}
    </div>
  );
}
