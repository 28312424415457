import React, { Component } from 'react';
import { ReactComponent as SearchIcon } from '../../../../../../assets/icons/013-search.svg';

class SearchInput extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: '',
    };

    this.handleChange = this.handleChange.bind(this);
  }

  handleChange = (event) => {
    this.props.getValue(event.target.value);
  };

  render() {
    const { type, placeholder, text } = this.props;

    return (
      <div className='search-input-wrapper'>
        <input
          className='search-input'
          type={type}
          placeholder={placeholder}
          onChange={this.handleChange}
          defaultValue={text}
        />
        <div className='search-input-icon'>
          <SearchIcon />
        </div>
      </div>
    );
  }
}

export default SearchInput;
