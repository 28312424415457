import { actionTypes } from './actions';
import { roleTypes as role } from './roles';

const permissions = {
  // == VISOR
  [actionTypes.SEE_TEACHER_NOTES_VISOR]: [role.TEACHER, role.EDITORIAL],
  [actionTypes.SEE_ASSISTANT_VISOR]: [role.EDITORIAL],
  // == EDITOR
  [actionTypes.SEE_TEACHER_NOTES_EDITOR]: [role.TEACHER, role.EDITORIAL],
  [actionTypes.SEE_ASSISTANT_EDITOR]: [role.EDITORIAL],
  [actionTypes.SEE_POST_OPTIONS]: [role.EDITORIAL],
};

/**
 * Devuelve si un el role puede realizar la accion
 * Ejemplo: canAction(actionTypes.EDIT_ALL_DLO, roleTypes.ADMIN)
 * @param {string} action
 * @param {string} role
 */
export const canAction = (action, role) => {
  const usersCanAction = permissions[action] ? permissions[action] : [];
  return usersCanAction.includes(role);
};
