import PropTypes from 'prop-types';
import React from 'react';
import TabsModelA from '../../../../../components/tabs/TabsModelA/TabsModelA';

class TabsContentSettingsSidebar extends React.Component {
  render() {
    const { children, classNameAdd, t } = this.props;
    return (
      <TabsModelA
        titleTabsList={[t('Content', { ns: 'editor' }), t('Settings', { ns: 'editor' })]}
        classNameAdd={classNameAdd}
      >
        {/** Children con el contenido de la pestaña Content */}
        {children[0]}
        {/** Children con el contenido de la pestaña Setting */}
        {children[1]}
      </TabsModelA>
    );
  }
}

export default TabsContentSettingsSidebar;

TabsContentSettingsSidebar.propTypes = {
  children: PropTypes.array.isRequired,
};
