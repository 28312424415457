import { useEffect, useState } from 'react';
import { MINT_WRAPPER_CLASS } from '../config';
import ConfigGlobalSingleton from '../config/ConfigGlobalSingleton';
import { IMathRenderEngine } from '../types/IMathRenderEngine';
import { renderKatex } from '../utils/renderKatex';
import { renderMathJax } from '../utils/renderMathjax';

const mathClass = '.fr-math, .fr-math-v2, .fr-math-v3';

export function renderMath(element, onRender = () => {}) {
  if (element) {
    const mathRenderEngine = ConfigGlobalSingleton.getInstance().getMathRenderEngine();
    if (mathRenderEngine === IMathRenderEngine.KATEX) renderKatex(element, onRender);
    else
      renderMathJax(element, onRender, () => {
        // hay que procesar el dom entero de lemonade ya que no estaba cargada la lib y han quedado elementos sin procesar
        renderMathJax(document.querySelector(`.${MINT_WRAPPER_CLASS}`), onRender);
      });
  }
}

export default function useMathRender(rootElement, rules = [], onSuccess = null, init = false, onBegin = null) {
  const [isUpdated, setUpdate] = useState(0); // TODO: Ver si se usa
  useEffect(() => {
    onBegin && onBegin();
    const hasMath = rootElement?.current?.querySelector(mathClass);
    if (hasMath || init) {
      // Al cargar la lib en la config, perdemos el poder de ocultar y mostrar el elemento mientras está render
      renderMath(rootElement?.current, nodes => {
        setUpdate(1);
        setTimeout(() => {
          onSuccess && onSuccess();
        }, 100);
      });
    } else {
      onSuccess && onSuccess();
    }
  }, rules);
  return { isUpdated };
}
