import { MINT_WRAPPER_CLASS } from '.';
import { IMathRenderEngine } from '../types/IMathRenderEngine';
import { createLinkTags } from '../utils/createLinkTag';
import { createInlineScript, createScriptTags } from '../utils/createScriptTag';

function addMathJax() {
  const mathJaxConfig = `
    function configMathJax () {
      try{
        MathJax.Hub.Config({"fast-preview": {disabled: true},asciimath2jax: { processClass: "fr-math", ignoreClass: "no-mathjax|editor-page"},"TeX": { extensions: ["color.js","cancel.js"] },"HTML-CSS": { scale: 100, linebreaks: { automatic: true }, availableFonts : ["TeX"], preferredFont : "TeX", webFont : "STIX-Web"},SVG: { scale: 100, linebreaks: { automatic:false }, font: "STIX-Web"}, displayAlign: "left",showMathMenu: false,showMathMenuMSIE: false,showProcessingMessages: false, messageStyle: "none"});
       }catch(e){ /* setTimeout (configMathJax, 300) */}
    }
    configMathJax();`;
  const cdnPath = 'https://cdnjs.cloudflare.com/ajax/libs/mathjax/2.7.7/MathJax.js?config=TeX-MML-AM_SVG'; // TeX-MML-AM_HTMLorMML // TeX-MML-AM_SVG // TeX-MML-AM_CHTML No renderiza los acentos, descartado
  if (window && !window.MathJax && !window.loadingMathLib) {
    window.loadingMathLib = true;
    return createScriptTags({ id: 'MathJax-script', src: cdnPath })
      .then(() => {
        return createInlineScript({
          id: 'MathJax-config',
          content: mathJaxConfig,
          attrs: { type: 'text/x-mathjax-config' },
        });
      })
      .catch(e => console.log('error cargando Mathjax', e));
  } else {
    return Promise.resolve();
  }
}

function addAutoRenderKatex() {
  const autoRendercdnPath = 'https://cdn.jsdelivr.net/npm/katex@0.16.9/dist/contrib/auto-render.min.js';
  if (window && !window.renderMathInElement) {
    return createScriptTags({ id: 'Katex-auto-render', src: autoRendercdnPath });
  } else {
    return Promise.resolve();
  }
}

function addKatex() {
  const cdnPath = 'https://cdn.jsdelivr.net/npm/katex@0.16.9/dist/katex.min.js';
  if (window && !window.katex && !window.loadingMathLib) {
    window.loadingMathLib = true;
    return createScriptTags({ id: 'Katex-script', src: cdnPath });
  } else {
    return Promise.reject();
  }
}

function addKatexCss() {
  const cdnStylePath = 'https://cdn.jsdelivr.net/npm/katex@0.16.9/dist/katex.min.css';
  createLinkTags({ id: 'Katex-style', src: cdnStylePath });
}

function firstRender(mathEngine = IMathRenderEngine.MATHJAX) {
  // console.log('FIRST RENDER ', mathEngine);
  const lemonadeContainer = document.getElementsByClassName(MINT_WRAPPER_CLASS);
  if (lemonadeContainer.length > 0)
    if (window.renderMathInElement && mathEngine === IMathRenderEngine.KATEX) {
      window.renderMathInElement(lemonadeContainer[0]);
    } else if (window.MathJax) {
      window.MathJax.Hub.Queue(['Typeset', window.MathJax.Hub, lemonadeContainer[0]]);
    }
}

export function addLatex(mathEngine) {
  console.log('ADD MATH ENGINE ', mathEngine);
  if (mathEngine === IMathRenderEngine.KATEX) {
    addKatex().then(() => {
      addKatexCss();
      addAutoRenderKatex().then(() => firstRender(mathEngine));
    });
  } else {
    addMathJax().then(() => firstRender(mathEngine));
  }
}
