import React from 'react';
import posed from 'react-pose';
import { ReactComponent as CloseIcon } from '../../../../../../assets/icons/close.svg';
import useGeneral from '../../../../../../hooks/useGeneral';

const Container = posed.div({
  hidden: { x: -310, transition: { duration: 300, ease: 'circIn' } },
  visible: { x: 0, delayChildren: 150, staggerChildren: 50, transition: { duration: 350, ease: 'circOut' } },
});

const Item = posed.li({
  hidden: { x: -20, opacity: 0 },
  visible: { x: 0, opacity: 1, transition: { duration: 150, type: 'spring', stiffness: 200 } },
});

const CollectionsMenu = ({ open, closeMenu, children }) => {
  const { t } = useGeneral();
  return (
    <Container className='collections-menu' pose={open ? 'visible' : 'hidden'}>
      <div className='collections-menu__header'>
        <h6 className='collections-menu__title'>{t('create_block_collection_menu_title', { ns: 'editor' })}</h6>
        <button className='collections-menu__close' onClick={closeMenu}>
          <CloseIcon />
        </button>
      </div>
      <ul className='collections-menu__list-container'>
        {children.map((Link, idx) => (
          <Item key={`group__${idx}`} className='collections-menu__list-item'>
            {Link}
          </Item>
        ))}
      </ul>
    </Container>
  );
};

export default CollectionsMenu;
