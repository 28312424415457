import { actionTypes } from '../actions';
import { canAction } from '../permissions';

export function canSeeTeacherNotesEditor(userRoleGuid) {
  var canSee = false;
  if (userRoleGuid) {
    if (canAction(actionTypes.SEE_TEACHER_NOTES_EDITOR, userRoleGuid)) {
      canSee = true;
    }
  }
  return canSee;
}

export function canSeeAssistantEditor(userRoleGuid) {
  var canSee = false;
  if (userRoleGuid) {
    if (canAction(actionTypes.SEE_ASSISTANT_EDITOR, userRoleGuid)) {
      canSee = true;
    }
  }
  return canSee;
}

export function canSeePostOptions(userRoleGuid) {
  return canAction(actionTypes.SEE_POST_OPTIONS, userRoleGuid);
}
