export function createScriptTags({ id, src }) {
  return new Promise(function (resolve, reject) {
    const s = document.createElement('script')
    let r = false
    s.type = 'text/javascript'
    s.id = id
    s.src = src
    // s.async = true;
    s.onerror = function (err) {
      reject(err, s)
    }
    s.onload = s.onreadystatechange = function () {
      // console.log(this.readyState); // uncomment this line to see which ready states are called.
      if (!r && (!this.readyState || this.readyState === 'complete')) {
        // console.log('se ha cargado Mathjax correctamente', window.MathJax);
        r = true
        resolve()
      }
    }
    const t = document.getElementsByTagName('script')[0]
    t.parentElement.insertBefore(s, t)
  })
}

export function createInlineScript({ id, content, attrs = {} }) {
  if (document.getElementById(id)) return Promise.resolve()

  return new Promise((resolve) => {
    const tag = document.createElement('script')
    tag.id = id
    tag.innerHTML = content
    Object.entries(attrs).forEach((entry) => (tag[entry[0]] = entry[1]))
    document.head.append(tag)
    resolve()
  })
}
