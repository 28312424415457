/**
 * External dependencies
 */
import classNames from 'classnames';
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
/**
 * Internal dependencies
 */
import { ReactComponent as CloseIcon } from '../../assets/icons/close.svg';

class FloatingFullscreen extends Component {
  constructor(props) {
    super(props);
    this.el = document.createElement('div');
    this.container = props.container ? document.getElementById(props.container || '') || document.body : document.body;    

    this.state = {
      visible: props.visible || false,
      mounted: false,
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState({
      visible: nextProps.visible || false,
    });
  }

  componentDidMount() {
    // The portal element is inserted in the DOM tree after
    // the Modal's children are mounted, meaning that children
    // will be mounted on a detached DOM node. If a child
    // component requires to be attached to the DOM tree
    // immediately when mounted, for example to measure a
    // DOM node, or uses 'autoFocus' in a descendant, add
    // state to Modal and only render the children when Modal
    // is inserted in the DOM tree.
    this.container.appendChild(this.el);
    if (this.state.visible) {
      document.body.style.overflow = 'hidden'; // TODO Ver si al poner esto en un host estoy ocultando el scroll del body y lo mismo no aplica
    }
    setTimeout(() => this.setState({ mounted: true }), 100);
  }

  componentWillUnmount() {
    this.container.removeChild(this.el);
    document.body.style.overflow = 'unset';
  }

  handleClose = (event) => {
    event.preventDefault();
    this.setState({ visible: false });
    try {
      this.props.onClose && this.props.onClose()
    } catch (e) {}
  };

  render() {
    const { title } = this.props;
    const sidebarClasses = classNames({
      'mint-floating-fullscreen': true,
      'mint-floating-fullscreen--open': this.state.visible && this.state.mounted,
    });
    const sidebarOverlayClasses = classNames({
      'mint-floating-fullscreen__overlay': true,
      'mint-floating-fullscreen__overlay--active': this.state.visible,
    });
    return ReactDOM.createPortal(
      <React.Fragment>
        <div className={sidebarClasses}>
          <div className='mint-floating-fullscreen__container mint-floating-fullscreen__edit mint-fade-in'>
            <div className='mint-floating-fullscreen__header'>
              <h6 className='mint-floating-fullscreen__title'>{title}</h6>
              <button className='mint-floating-fullscreen__close' onClick={this.handleClose}>
                <CloseIcon />
              </button>
            </div>
            <div className='block-edit'>
              <div className='block-edit__content'>{this.props.children}</div>
            </div>
          </div>
        </div>
        <div className={sidebarOverlayClasses} onClick={this.handleClose} />
      </React.Fragment>, 
      this.el
    );
  }
}

export default FloatingFullscreen;
