import React from 'react';

const AsideMenuButton = (props) => {
  const { children, modifier = 'primary', selected = false } = props;
  const childProps = { ...props };
  delete childProps.translate;
  return (
    <button className={`aside-menu-button aside-menu-button--${modifier} ${selected && 'selected'}`} {...childProps}>
      <div className='aside-menu-button__selected' />
      <div className='aside-menu-button__content'>{children}</div>
    </button>
  );
};

export default AsideMenuButton;
