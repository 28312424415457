export function renderMathJax(
  element,
  onRenderElements = () => {
    console.log('MathJaxQueue:: callback not defined');
  },
  onMathJaxReady = () => {
    console.log('MathJaxQueue:: onLibReady not defined');
  }
) {
  const processMath = (nodes) => {
    try {
      window.MathJax.Hub.Queue([
        'Typeset',
        window.MathJax.Hub,
        nodes,
        () => {
          // console.log(nodes)
          onRenderElements(nodes);
        },
      ]);
    } catch (e) {
      console.log(e);
    }
  };

  if (window.MathJax) {
    processMath(element);
  }
}
