/*eslint-disable*/
import FroalaEditor from 'froala-editor';
(function() {
  'use strict';
  const FE = FroalaEditor;

  FE.DefineIcon('numGroup', {
    NAME: 'numGroup',
    PATH:
      'M7.795,2.523l0,1.458l-3.887,0l0,16.038l3.887,0l0,1.458l-4.616,0c-0.403,0 -0.729,-0.326 -0.729,-0.729l0,-17.496c0,-0.403 0.326,-0.729 0.729,-0.729l4.616,0Zm8.748,0l4.278,0c0.403,0 0.729,0.326 0.729,0.729l0,17.496c0,0.403 -0.326,0.729 -0.729,0.729l-4.278,0l0,-1.458l3.549,0l0,-16.038l-3.549,0l0,-1.458Zm-4.543,7.804l4.601,-4.601l1.673,1.673l-4.601,4.601l4.601,4.601l-1.673,1.673l-4.601,-4.601l-4.601,4.601l-1.673,-1.673l4.601,-4.601l-4.601,-4.601l1.673,-1.673l4.601,4.601Z ',
  });

  FE.RegisterCommand('numGroup', {
    title: 'Number Group',
    focus: true,
    undo: true,
    refreshAfterCallback: true,
    callback: function() {
      const cleanNode = function(n) {
        n.innerText.length === 0 && n.parentNode.removeChild(n);
      };
      this.format.toggle('span', { class: 'no-break' });
      this.el.querySelectorAll('.fr-class-no-break').forEach(cleanNode);
    },
  });
})();
