import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { ReactComponent as DownIcon } from '../../../../../../assets/icons/arrow_down.svg';

class FilterDropdown extends Component {
  constructor(props) {
    super(props);
    this.state = {
      listOpen: false,
      headerTitle: this.props.title,
    };
    this.closeMenu = this.closeMenu.bind(this);
  }

  toggleList() {
    this.setState(
      (prevState) => ({
        listOpen: !prevState.listOpen,
      }),
      () => {
        document.addEventListener('click', this.closeMenu);
      }
    );
  }

  selectOption(title, key) {
    this.setState((prevState) => ({
      listOpen: !prevState.listOpen,
    }));
    this.setState({ headerTitle: title });
    this.props.onChange && this.props.onChange(key);
  }

  closeMenu() {
    this.setState({ listOpen: false }, () => {
      document.removeEventListener('click', this.closeMenu);
    });
  }

  render() {
    const { list } = this.props;
    const { listOpen, headerTitle } = this.state;
    return (
      <div className='filter-dd-wrapper'>
        <div className='filter-dd-header' onClick={() => this.toggleList()}>
          <div className='filter-dd-header-title'>
            {headerTitle}
            {<DownIcon />}
          </div>
        </div>
        {
          <ul className='filter-dd-list' style={{ display: listOpen ? 'block' : 'none' }}>
            {list.map((item) => (
              <li className='filter-dd-list-item' key={item.id} onClick={() => this.selectOption(item.title, item.key)}>
                {item.icon && <img src={item.icon} />}
                {item.title}
              </li>
            ))}
          </ul>
        }
      </div>
    );
  }
}

FilterDropdown.propTypes = {
  title: PropTypes.string,
  onChange: PropTypes.func,
  list: PropTypes.array,
};

export default FilterDropdown;
