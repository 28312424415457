import React from 'react';
import { hasData } from '../../../../../services/string.services';
import TabsContentSettingsSidebar from '../TabsContentSettingsSidebar/TabsContentSettingsSidebar';
import TabSettingsSidebar, {
  SETTING_BACKGROUND_COLOR,
  SETTING_PADDING_BUTTON,
  SETTING_PADDING_TOP,
  SETTING_TEACHER_ONLY,
} from '../TabSettingsSidebar/TabSettingsSidebar';

export const DEFAULT_SETTINGS = [
  SETTING_PADDING_TOP,
  SETTING_PADDING_BUTTON,
  SETTING_BACKGROUND_COLOR,
  SETTING_TEACHER_ONLY,
];

export function getSettingsTab(settingsAditional) {
  return [...DEFAULT_SETTINGS, ...settingsAditional];
}
/**
 * Es el componente por defecto que va a tener casi todos los Itemblock en su componente TabsContentSettingsSidebar
 */
class TabsContentSettingsSidebarDefault extends React.Component {
  // particularNameClassSection: Es por ejemplo: "ItemBlocks_Text_Heading"

  render() {
    const {
      children,
      settingsAditional,
      particularNameClassSection,
      classAdd,
      keyItem,
      backgroundColor,
      paddingTop,
      paddingBottom,
      teacherOnly = false,
      item,
      onChangeItem,
      courseId,
      classTabs,
      optionsImageSizes,
      t,
    } = this.props;
    const finalSettings = getSettingsTab(settingsAditional || []);
    return (
      <TabsContentSettingsSidebar
        t={t}
        classNameAdd={
          'tabs-sidebar' + (hasData(particularNameClassSection) ? ' ' + particularNameClassSection + '__sidebar' : '')
        }
      >
        {/** Children con el contenido de la pestaña Content */}
        <div className='children-content'>
          <section className={'mint-tab-content-settings-sidebar ' + (classTabs || '')}>{children}</section>
        </div>
        {/** Children con el contenido de la pestaña Settings */}
        <div className='children-settings'>
          <section className={'mint-tab-content-settings-sidebar'}>
            <TabSettingsSidebar
              item={item}
              onChangeItem={onChangeItem}
              backgroundColor={backgroundColor}
              paddingTop={paddingTop}
              paddingBottom={paddingBottom}
              listSettings={finalSettings}
              isTeacherOnly={teacherOnly}
              optionsImageSizes={optionsImageSizes}
            ></TabSettingsSidebar>
          </section>
        </div>
      </TabsContentSettingsSidebar>
    );
  }
}

export default TabsContentSettingsSidebarDefault;
