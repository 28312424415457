export default function initScorm(exercisesReferences, lessonId) {
  if (!window.sco_instance) {
    window.sco_instance = [];
  }
  if (!window.sco_instance[lessonId]) {
    window.sco_instance[lessonId] = new ScormExercise();
    window.sco_instance[lessonId].setScoreValues(0, 100, 50);
    window.sco_instance[lessonId].setIdentifiers(exercisesReferences);
    if (exercisesReferences.length == 0) {
      window.sco_instance[lessonId].init({ indentifier: lessonId });
      window.sco_instance[lessonId].setLessonComplete();
    } else {
      const sd = getSuspendData(lessonId);
      if (sd && Object.keys(sd)?.length === exercisesReferences?.length) repaint(lessonId);
      /* window.addEventListener('beforeunload', function(e) { // no nos garantiza de que funcione al 100%, depende de la latencia de la red
        console.log('beforeunload', lessonId);
        finishScorm(lessonId);
      }); */
    }
  }
}

export function finishScorm(lessonId) {
  if (lessonId && window.sco_instance?.[lessonId]) {
    window.sco_instance[lessonId].finish?.();
  }
}

function getSuspendData(lessonId) {
  // Dummy data
  /* const suspendDataDummyOriginal = [
    { '32f30ef0-b8d8-11ed-b149-531b36cd376b': { '1': ['múltiplo', 'fotosfera', 'atmosfera', 'corona'] } },
    { '2e02e8b0-b8d9-11ed-aef9-f9a5adb5442b': { '1': [0, 1, 2, 3] } },
  ]; */
  /* const suspendDataDummySimplificado = {
    '32f30ef0-b8d8-11ed-b149-531b36cd376b': [['múltiplo', 'fotosfera', 'atmosfera', 'corona']],
    '2e02e8b0-b8d9-11ed-aef9-f9a5adb5442b': [[0, 1, 2, 3]],
  }; */
  /* const sd = {
    'b933675b-ef4c-4c98-854f-c5661f064f74': [['2']],
    'e8459ab2-3582-4d9b-8517-4c3ae78e2c28': [['<p>hola</p>']],
    '798cb8e2-5c4a-48db-aa26-9768b1047297': [[['1']]],
    '9e98c3da-7a08-4d0e-a423-46a7e6f748f6': [[['0']]],
    'f28a3a95-d368-4a53-a6bf-39f86fe5bcea': [[['2']]],
    '6bf6b8ed-0449-4d21-88db-c22067bd62e2': [[['1']]],
    'aad9420b-1487-4ac3-b2b9-f105e32f73b2': [[['1']]],
  }; */

  /* const sd_o = {
    'd5489138-7fb0-4b4a-8b53-8f02c3fc6b4e': [['0']],
    '68bc6c1c-3793-47fb-892c-74fca79218be': [['aaaa', 'bbbb', 'cccc', 'dddd']],
  }; */

  if (lessonId && window.sco_instance?.[lessonId]) {
    return window.sco_instance[lessonId].get();
  }
  const sd = window?.getSuspendData?.();
  return sd;
}

export function getSuspendDataByReference(reference, lessonId) {
  const suspendData = getSuspendData(lessonId);
  return suspendData?.[reference]?.[0]; // ?.[0]; // En la lib de SCORM hay que arreglar esto de que sea [0][0] con uno es suficiente
  // Este caso era para el dummy original que Esteban cambiará, dejar comentado cuando esté listo
  /* const suspendDataReferenceOriginal = suspendData?.find(obj => obj.hasOwnProperty(reference));
  console.log('getSuspendDataByReference', suspendDataReferenceOriginal?.[reference]?.['1']);
  return suspendDataReferenceOriginal?.[reference]?.['1']; */
}

export function navigateTo(prevLessonId, lessonId) {
  if (lessonId && window.sco_instance?.[prevLessonId]) {
    // console.log('navigateTo', `item_${lessonId}`);
    window.sco_instance[prevLessonId].navigateTo(`item_${lessonId}`);
    return true;
  }
}

function repaint(lessonId) {
  if (lessonId && window.sco_instance?.[lessonId]) {
    console.log('repaint', lessonId);
    window.sco_instance[lessonId].repaint?.();
  }
}
