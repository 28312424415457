import { actionTypes } from '../actions';
import { canAction } from '../permissions';

export function canSeeTeacherNotesVisor(userRoleGuid) {
  var canSee = false;
  if (userRoleGuid) {
    if (canAction(actionTypes.SEE_TEACHER_NOTES_VISOR, userRoleGuid)) {
      canSee = true;
    }
  }
  return canSee;
}

export function canSeeAssistantVisor(userRoleGuid) {
  var canSee = false;
  if (userRoleGuid) {
    if (canAction(actionTypes.SEE_ASSISTANT_VISOR, userRoleGuid)) {
      canSee = true;
    }
  }
  return canSee;
}
