export function createLinkTags({ id, src }) {
  return new Promise(function (resolve, reject) {
    const lnk = document.createElement('link')
    lnk.href = src
    lnk.rel = 'stylesheet'
    lnk.type = 'text/css'
    ;(document.head || document.documentElement).appendChild(lnk)

    let r = false

    // s.async = true;
    lnk.onerror = function (err) {
      reject(err, lnk)
    }
    lnk.onload = lnk.onreadystatechange = function () {
      // console.log(this.readyState); // uncomment this line to see which ready states are called.
      if (!r && (!this.readyState || this.readyState === 'complete')) {
        // console.log('se ha cargado Mathjax correctamente', window.MathJax);
        r = true
        resolve()
      }
    }
    const t = document.getElementsByTagName('link')[0]
    t.parentElement.insertBefore(lnk, t)
  })
}
