import i18next from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import editorCA from './ca/editor.json';
import visorCA from './ca/visor.json';
import editorEn from './en/editor.json';
import visorEn from './en/visor.json';
import editorEs from './es/editor.json';
import visorEs from './es/visor.json';
import editorEU from './eu/editor.json';
import visorEU from './eu/visor.json';
import editorGL from './gl/editor.json';
import visorGL from './gl/visor.json';
import editorVL from './vl/editor.json';
import visorVL from './vl/visor.json';

import editorPT from './pt/editor.json';
import visorPT from './pt/visor.json';

const i18nMint = i18next.createInstance();
i18nMint
  .use(LanguageDetector)
  // .use(XHR)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    fallbackLng: 'es',
    resources: {
      en: { visor: visorEn, editor: editorEn },
      es: { visor: visorEs, editor: editorEs },
      ca: { visor: visorCA, editor: editorCA },
      gl: { visor: visorGL, editor: editorGL },
      eu: { visor: visorEU, editor: editorEU },
      vl: { visor: visorVL, editor: editorVL },
      pt: { visor: visorPT, editor: editorPT },
    },
    // Set default namespace
    // defaultNS: 'common',
    keySeparator: false, // we do not use keys in form messages.welcome

    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

export default i18nMint;
