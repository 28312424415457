import { ASSETS_DEFAULT_DOMAIN } from '../config';
import { blockList } from './blockList';

// TODO: llevar esto a un fichero de config
const URL_BASE = ASSETS_DEFAULT_DOMAIN;
const ASSETS_SRC_URI = URL_BASE + '/mintcontent/assets/'; // Mint path
const BLOCK_THUMBNAILS = ASSETS_SRC_URI + 'blocks'; // Mint Path
export const PROPERTY_HIDE_INSIDE_MENU_CREATE = 'hideInsideMenuCreate';

// TODO añadir path al servidor donde están los bloques
const imagePath = (path) => `${BLOCK_THUMBNAILS}/${path}`;

function flatten(list) {
  return list.reduce((acc, next) => acc.concat(next), []);
}

function createGetBlockList(data, translate) {
  return flatten(
    data.types.map((itemGroup) =>
      flatten(
        itemGroup.families.map((family) => ({
          id: family.name,
          blocks: family.variants
            ? family.variants.map((variant) => ({
                caption: translate(variant.name, { ns: 'editor' }),
                src: imagePath(variant.image),
                ...(variant.customEditor && { customEditor: true }),
                ...(variant.showSettingsEditor && { showSettingsEditor: true }),
                ...(variant.hideControlsEditor && { hideControlsEditor: true }),
                identity: {
                  type: itemGroup.name,
                  family: variant.familyName || family.name,
                  variant: variant.key,
                },
              }))
            : [],
        }))
      )
    )
  );
}

// Une las variantes cuando se indica que hay que agrupar en la familia, para el menu lateral
function createGetBlockListExclusive(data, translate) {
  let memo = null;

  // == Oculto aquellos tipos que tiene la propiedad hideInsideMenuCreate a true
  const typesFilter = data.types.filter(
    (type) =>
      !(
        Object.prototype.hasOwnProperty.call(type, PROPERTY_HIDE_INSIDE_MENU_CREATE) &&
        type[PROPERTY_HIDE_INSIDE_MENU_CREATE]
      )
  );
  memo = flatten(
    typesFilter.map((itemGroup) => {
      if (itemGroup.grouped) {
        itemGroup.families = [
          {
            name: itemGroup.name,
            variants: flatten(
              itemGroup.families.map((family) => {
                return family.variants.map((variant) => ({ familyName: family.name, ...variant }));
              })
            ),
          },
        ];
      }
      return flatten(
        itemGroup.families.map((family) => ({
          id: family.name,
          blocks: family.variants
            ? family.variants.map((variant) => ({
                caption: translate(variant.name, { ns: 'editor' }),
                src: imagePath(variant.image),
                ...(variant.customEditor && { customEditor: true }),
                ...(variant.showSettingsEditor && { showSettingsEditor: true }),
                ...(variant.hideControlsEditor && { hideControlsEditor: false }),
                identity: {
                  type: itemGroup.name,
                  family: variant.familyName ? variant.familyName : family.name, // == Cuando agrupamos varios porque tenga el valor itemGroup.grouped le ponemos como nuevo atributo a variant la familyName para luego distinguir de que familia era
                  variant: variant.key,
                },
              }))
            : [],
        }))
      );
    })
  );
  return memo;
}

function createFlattenBlockList(blockList) {
  const memo = flatten(blockList.map((itemGroup) => itemGroup.blocks));
  return memo;
}

export const getBlockList = (translate) => createGetBlockList(blockList, translate);
export const getBlockListExclusive = (translate) => createGetBlockListExclusive(blockList, translate);
export const getFlattenBlockList = (translate) => createFlattenBlockList(getBlockList(translate));
export const getBlockFamily = (family, translate) => getBlockList(translate).find((item) => item.id === family);
export const findItemBlock = (type, family, variant, translate) =>
  getFlattenBlockList(translate).find(
    (item) =>
      item &&
      item.identity &&
      item.identity.type === type &&
      item.identity.family === family &&
      item.identity.variant === variant
  );
export const isCustomEditor = (item, translate) => {
  const itemBlockFind = item ? findItemBlock(item.type, item.family, item.variant, translate) : null;
  return (
    (itemBlockFind && itemBlockFind.customEditor) || (item.variant && item.variant.indexOf('labeledgraphic') !== -1)
  );
};
export const isShowSettingsEditor = (item, translate) => {
  const itemBlockFind = item ? findItemBlock(item.type, item.family, item.variant, translate) : null;
  return itemBlockFind && itemBlockFind.showSettingsEditor;
};
export const isHideControlsEditor = (item, translate) => {
  const itemBlockFind = item ? findItemBlock(item.type, item.family, item.variant, translate) : null;
  return itemBlockFind && itemBlockFind.hideControlsEditor;
};
