import PropTypes from 'prop-types';
import React from 'react';

/**
 * Componente de Tabs modelo A.
 * Por defecto abre la tab 0
 */
class TabsModelA extends React.Component {
  constructor(props) {
    super(props);

    this.clickTab = this.clickTab.bind(this);
    this.state = {
      idTabActive: this.getNumTabs() > 0 ? 0 : null,
    };
  }

  getNumTabs() {
    return this.props.children && this.props.children.length > 0 ? this.props.children.length : 0;
  }

  clickTab(e, index) {
    e.preventDefault();
    this.setState({
      idTabActive: index,
    });
  }

  render() {
    const { idTabActive } = this.state;
    const { children, titleTabsList, classNameAdd } = this.props;
    return (
      <div className={'mint-tabs-model-a ' + classNameAdd}>
        <div className='mint-tabs-model-a__tabs-container'>
          <ul className='mint-tabs-model-a__tabs'>
            {titleTabsList
              ? titleTabsList.map((titleTab, index) => (
                  <li
                    className={
                      'mint-tabs-model-a__tab' +
                      (idTabActive !== null && idTabActive === index ? ' mint-tabs-model-a__tab--active' : '')
                    }
                    key={index}
                    onClick={(e) => this.clickTab(e, index)}
                  >
                    <a href=''>{titleTab}</a>
                  </li>
                ))
              : ''}
          </ul>
        </div>
        <div className='mint-tabs-model-a__content'>
          {children && children.length !== undefined ? (
            children.map((childrenParcial, index) =>
              idTabActive !== null && idTabActive === index ? (
                <div className='mint-tabs-model-a-children' key={index}>
                  {childrenParcial}
                </div>
              ) : (
                ''
              )
            )
          ) : children ? ( // Tengo un solo hijo y lo pinto
            <div className='mint-tabs-model-a-children'>{children}</div>
          ) : (
            ''
          )}
        </div>
      </div>
    );
  }
}

export default TabsModelA;

TabsModelA.propTypes = {
  // children: PropTypes.array.isRequired || PropTypes.object.isRequired, // == Listado de contenido de las distintas tabs, child es un array y en cada posicion es el contenido de esa pestaña
  titleTabsList: PropTypes.array.isRequired, // == Listado de titulos, el titulo de la posicion 0 de este array corresponde a la primera pestaña
};
