/*eslint-disable*/
import FroalaEditor from 'froala-editor';
import { addClass, removeClass } from '../../../../_highlighter/services/html.services';
import {
  MINT_BLOCKS_SIDEBAR_CONTENT_KEYBOARD_VISIBLE,
  MINT_ID_BLOCKS_SIDEBAR_CONTENT,
} from '../../../../components/FloatingSidebar/FloatingSidebar';
import ConfigGlobalSingleton from '../../../../config/ConfigGlobalSingleton';
import { IMathRenderEngine } from '../../../../types/IMathRenderEngine';
(function addMathPluginv3() {
  const DELIMITER_BEGIN_LATEX = '\\(';
  const DELIMITER_END_LATEX = '\\)';
  let mfes = {}; // vamos a guardar una instancia de mathfield por cada popup nuevo
  let firstEditor = undefined; // como se reutilizan los popup entre froalas, nos quedamos con la primera instancia abierta que será el reutilizable
  FroalaEditor.POPUP_TEMPLATES['mathPluginv3.popup'] = '[_BUTTONS_][_CUSTOM_LAYER_]';
  FroalaEditor.POPUP_TEMPLATES['mathPluginv3.edit'] = '[_BUTTONS_]';

  // Define popup buttons.
  Object.assign(FroalaEditor.DEFAULTS, {
    popupButtonsv3: ['popupClosev3'],
    mathPluginEditButtonsv3: ['mathPluginv3Edit', '|', 'mathPluginv3Remove'],
  });
  var delayRerender;
  // The custom popup is defined inside a plugin (new or existing).
  FroalaEditor.PLUGINS.mathPluginv3 = function(editor) {
    function _init() {
      editor.events.$on(editor.$el, 'click touchend', 'span.fr-math-v3', touchEndElement);
      editor.events.on('html.processGet', resetMathDiv); // para cuando se mande a servidor quitar los divs que genera mathjax
      editor.events.on('initialized', function() {
        this.opts.htmlAllowedEmptyTags.push('span'); // tengo que añadirlo si no borra el span vacio de mathjax
        setTimeout(function() {
          renderMathExpression(getMathElements(editor.el));
        }, 100);
      });
      editor.events.on('html.set', function() {
        //Retrasamos el render porque el usuario puede estar escribiendo
        clearTimeout(delayRerender);
        delayRerender = setTimeout(function() {
          renderMathExpression(getMathElements(editor.el));
        }, 2000);
      });
    }

    // Create custom popup.
    function initPopup() {
      getEventListenersPolyfill();
      // Popup buttons.
      var popup_buttons = '';
      // Create the list of buttons.
      if (editor.opts.popupButtonsv3.length > 1) {
        popup_buttons += '<div class="fr-buttons fr-tabs">';
        popup_buttons += editor.button.buildList(editor.opts.popupButtonsv3);
        popup_buttons += '</div>';
      }
      // Load popup template.
      var template = {
        buttons: popup_buttons,
        custom_layer:
          '<div class="fr-math-v3-insert-layer fr-layer fr-active" id="fr-math-v3-insert-layer-"' +
          editor.id +
          '" data-mouseenter-event-set="true" data-popup-id="' +
          editor.id +
          '">' +
          '<div class="fr-input-line" style="display: block;">' +
          '<div class="fr-math-v3-field"></div>' +
          '</div>' +
          '<div class="fr-action-buttons"><button class="fr-command fr-submit fr-math-v3-action-button" role="button" data-cmd="mathInsertv3" href="#" tabindex="3" type="button">Insert</button></div>' +
          '</div>',
      };

      // Create popup.
      var $popup = editor.popups.create('mathPluginv3.popup', template);

      initMathField();
      return $popup;
    }

    // Show the popup
    function showPopup(isQuickInsert = false) {
      if (editor.selection) editor.selection.save(); // == Guardamos donde está el cursor para cuando hagamos la inserccion poder hacer un editor.selection.restore() y formar que el editor.html.insert se haga en dicha posicion, antes no hacia falta hacer esto pero ahora si porque sino se inserta alfinal del texto LEMO-599

      let selection = editor.selection.text();

      // Get the popup object defined above.
      var $popup = editor.popups.get('mathPluginv3.popup');

      // If popup doesn't exist then create it.
      // To improve performance it is best to create the popup when it is first needed
      // and not when the editor is initialized.
      if (!$popup) $popup = initPopup();

      // Set the editor toolbar as the popup's container.
      editor.popups.setContainer('mathPluginv3.popup', isQuickInsert ? editor.$sc : editor.$tb); //tb para toolbar; tooltip para quick insert

      // This will trigger the refresh event assigned to the popup.
      editor.popups.refresh('mathPluginv3.popup');

      // This custom popup is opened by pressing a button from the editor's toolbar.
      // Get the button's object in order to place the popup relative to it.
      var $btn = isQuickInsert ? $('.fr-qi-helper') : editor.$tb.find('.fr-command[data-cmd="mathButtonV3"]');

      // Set the popup's position.
      var left = $btn.offset().left + $btn.outerWidth() / 2;
      var top = $btn.offset().top + (!isQuickInsert ? (editor.opts.toolbarBottom ? 10 : $btn.outerHeight() - 10) : 0);

      // Show the custom popup.
      // The button's outerHeight is required in case the popup needs to be displayed above it.
      editor.popups.show('mathPluginv3.popup', left, top, $btn.outerHeight());
      let buttonAction = $popup.find('.fr-math-v3-action-button');
      if (buttonAction) {
        buttonAction.text('Insert');
        buttonAction.data('cmd', 'mathInsertv3');
      }

      let mfe = getMathfiledInstance();
      if (mfe) {
        if (selection.length > 0) {
          mfe.setValue(selection);
        } else mfe.setValue('');
        mfe.focus();
      }

      fixKeyboardMathDiv();
    }

    /** Hay un fix que no se permite manejar el teclado,
     * y analizando se ha visto que quitando el listener de tipo 'keydown'
     * que tiene el elemento fr-math-v3-insert-layer ya te permite usar el teclado
     * Con esta funcion lo que vamos hacer es eliminar dicho listener
     * */
    function fixKeyboardMathDiv() {
      var items = document.getElementsByClassName('fr-math-v3-insert-layer');
      for (var i = 0; i < items.length; i++) {
        if (typeof items[i].clearEventListeners !== 'undefined') {
          items[i].clearEventListeners('keydown');
        }
      }
    }

    function resetMathDiv(e) {
      if (e && editor.node.hasClass(e, 'fr-math-v3')) {
        e.textContent = e.getAttribute('data-original-math');
        e.setAttribute('class', (e.getAttribute('class') || '').replace('fr-math-v3-render', ''));
      } else if (e && e.nodeType === Node.ELEMENT_NODE)
        for (var n = e.querySelectorAll('.fr-math-v3'), i = 0; i < n.length; i++) resetMathDiv(n[i]);
    }

    function getEditButtons() {
      var e = '';
      if (editor.opts.mathPluginEditButtonsv3.length > 0) {
        e += '<div class="fr-buttons">\n      '.concat(
          editor.button.buildList(editor.opts.mathPluginEditButtonsv3),
          '\n      </div>'
        );
        var n = {
            buttons: e,
          },
          i = editor.popups.create('mathPluginv3.edit', n);
        return (
          editor.events.$on(editor.$wp, 'scroll.mathPluginv3-edit', function() {
            currentElement &&
              editor.popups.isVisible('mathPluginv3.edit') &&
              editor.events.disableBlur() &&
              a(currentElement);
          }),
          i
        );
      }
      return !1;
    }

    function a(e) {
      touchEndElement.call(e.get(0));
    }

    function touchEndElement() {
      currentElement = $(this);
      showPopupElement();
    }

    function showPopupElement() {
      var e = editor.popups.get('mathPluginv3.edit');
      if ((e || (e = getEditButtons()), e)) {
        editor.popups.setContainer('mathPluginv3.edit', editor.$sc);
        editor.popups.refresh('mathPluginv3.edit');
        var left = currentElement.offset().left + currentElement.outerWidth() / 2,
          top = currentElement.offset().top + currentElement.outerHeight();
        editor.popups.show('mathPluginv3.edit', left, top, currentElement.outerHeight());
      }
    }

    function getMathliveLatexFormula() {
      let mfe = getMathfiledInstance();
      var latexFormula = mfe.getValue('latex'); // 2/8/2022 se cambia a latex en vez de latex-expanded
      // console.log(mfe.getValue('latex-expanded'));
      // console.log(mfe.getValue('latex'));
      if (latexFormula.length > 1) {
        if (
          !latexFormula.startsWith(DELIMITER_BEGIN_LATEX) &&
          !latexFormula.endsWith(DELIMITER_END_LATEX) &&
          latexFormula.indexOf('`') !== 0 &&
          latexFormula.indexOf('$$') !== 0
        ) {
          // si tiene delimitador \(exp\) no añadir este `
          latexFormula = DELIMITER_BEGIN_LATEX + latexFormula + DELIMITER_END_LATEX;
        }
        latexFormula = latexFormula.replaceAll('mleft', 'left');
        latexFormula = latexFormula.replaceAll('mright', 'right');
        latexFormula = latexFormula.replaceAll('\\exponentialE', '\\mathrm{e}');
        latexFormula = latexFormula.replaceAll('\\doubleprime', '\\prime\\prime');
        latexFormula = latexFormula.replaceAll('mathcolor', 'color');
        latexFormula = latexFormula.replaceAll('^^b7', ' · ');
      }
      return latexFormula;
    }

    function createFragment(markup) {
      const fragment = document.createRange().createContextualFragment(markup);
      return fragment.firstElementChild;
    }

    function insertMathLatex(latex) {
      if (latex) {
        // Hay un problema con el simbolo de < en el span, se cree que es un html
        let newElement = ` <span class="fr-math-v3 fr-draggable" draggable="true" contenteditable="false" data-original-math="${latex}">${latex}</span> `;
        let newElementDom = createFragment(newElement);
        newElementDom.textContent = latex;
        if (editor.selection) editor.selection.restore(); // == Restauro donde está el cursor, antes no hacia falta hacer esto pero ahora si porque sino se inserta alfinal del texto LEMO-599
        editor.html.insert(newElementDom.outerHTML);
        renderMathExpression(newElement);
      }
    }

    function insert() {
      // == Insert mathlive
      let mfe = getMathfiledInstance();
      if (mfe) {
        var latexFormula = getMathliveLatexFormula();
        if (latexFormula.length > 1) {
          insertMathLatex(latexFormula);
          editor.popups.hideAll();
        }
      }
    }

    function remove() {
      if (currentElement) {
        var e = currentElement[0];
        editor.selection.setBefore(e) || editor.selection.setAfter(e);
        e.remove();
        editor.events.focus(true);
        editor.selection.restore();
        editor.html.fillEmptyBlocks();
        editor.undo.saveStep();
        editor.popups.hideAll();
        setTimeout(() => {
          editor.selection.restore();
          editor.html.fillEmptyBlocks();
          editor.undo.saveStep();
          editor.popups.hideAll();
        }, 100);
      }
    }

    function edit() {
      if (currentElement) {
        var latexFormula = currentElement[0].getAttribute('data-original-math');
        var left = currentElement.offset().left + currentElement.outerWidth() / 2,
          top = currentElement.offset().top + currentElement.outerHeight();
        var $popup = editor.popups.get('mathPluginv3.popup');
        if (!$popup) $popup = initPopup();
        editor.popups.setContainer('mathPluginv3.popup', editor.$sc);
        // This will trigger the refresh event assigned to the popup.
        editor.popups.refresh('mathPluginv3.popup');
        editor.popups.show('mathPluginv3.popup', left, top, currentElement.outerHeight());
        //Mathlive
        let mfe = getMathfiledInstance();
        if (mfe) {
          var latexFormulaFinal = latexFormula;
          if (latexFormula.startsWith(DELIMITER_BEGIN_LATEX) && latexFormula.endsWith(DELIMITER_END_LATEX)) {
            latexFormulaFinal = latexFormulaFinal.substring(
              DELIMITER_BEGIN_LATEX.length,
              latexFormulaFinal.length - DELIMITER_END_LATEX.length
            );
          }
          mfe.setValue(latexFormulaFinal);
          mfe.focus();
        }
        let buttonAction = $popup.find('.fr-math-v3-action-button');
        if (buttonAction) {
          buttonAction.text('Update');
          buttonAction.data('cmd', 'mathUpdatev3');
        }
      }
      fixKeyboardMathDiv();
    }

    function update() {
      if (currentElement) {
        var latexFormula = getMathliveLatexFormula();
        currentElement[0].setAttribute('data-original-math', latexFormula);
        resetMathDiv(currentElement[0]);
        editor.events.focus(true);
        editor.selection.setAfter(currentElement[0]);
        editor.selection.restore();
        renderMathExpression([currentElement[0]]);
        editor.popups.hideAll();
      }
    }

    // Hide the custom popup.
    function hidePopup() {
      editor.popups.hide('mathPluginv3.popup');
    }

    function getMathElements(_editor) {
      return _editor.getElementsByClassName('fr-math-v3');
    }

    function renderMathKatex(elements) {
      if (typeof renderMathInElement != 'undefined') {
        // console.log('Froala: renderMathKatex')
        renderMathInElement(elements, { throwOnError: true });
        editor.$el.find('.fr-math-v3').css('opacity', '1');
      }
    }

    function renderMathJax(elements) {
      if (typeof MathJax != 'undefined') {
        MathJax.Hub.Queue([
          'Typeset',
          MathJax.Hub,
          elements,
          function() {
            // callback finish render
          },
        ]);
      }
    }

    function renderMathExpression(elements) {
      if (elements && elements.length > 0) {
        let renderEngine = ConfigGlobalSingleton.getInstance().getMathRenderEngine();
        if (renderEngine === IMathRenderEngine.KATEX) {
          // renderMathInElement es una funcion global de katex auto render
          renderMathKatex(editor.el);
        } else {
          // MathJax es una variable definida fuera, a nivel general
          renderMathJax(elements);
        }
      }
    }

    function initMathField() {
      //  if(!mfe){ // == Lo comento porque sino la segunda vez que se accedia al editor no salia la caja del Mathlive
      //mfe = new MathfieldElement(); // == Si usaba //import {MathfieldElement} from 'mathlive'
      if (typeof window.MathLive !== 'undefined') {
        if (!mfes[editor.id]) {
          let mfe = new window.MathLive.MathfieldElement();
          // Set initial value and options
          //mfe.value = "\\frac{\\sin(x)}{\\cos(x)}";
          // Options can be set either as an attribute (for simple options)...
          mfe.setAttribute('virtual-keyboard-layout', 'dvorak');
          /* mfe.setOptions({
            virtualKeyboardMode: 'onfocus',
          });*/
          //mfe.virtualKeyboardMode = 'onfocus';
          mfe.addEventListener('focusin', () => mathVirtualKeyboard.show());
          mfe.setAttribute('class', 'froala-mathField__input');
          mfe.addEventListener('virtual-keyboard-toggle', (param1, param2) => {
            const sidebarContent = document.getElementById(MINT_ID_BLOCKS_SIDEBAR_CONTENT);
            if (sidebarContent) {
              if (mfe.virtualKeyboardState === 'visible') {
                addClass(sidebarContent, MINT_BLOCKS_SIDEBAR_CONTENT_KEYBOARD_VISIBLE);
              } else {
                removeClass(sidebarContent, MINT_BLOCKS_SIDEBAR_CONTENT_KEYBOARD_VISIBLE);
              }
            }
          });
          if (!firstEditor) firstEditor = editor.id;
          mfes[editor.id] = mfe;
        }
        var $popup = editor.popups.get('mathPluginv3.popup');
        let mathFieldContent = $popup.find('.fr-math-v3-field');
        mathFieldContent.append(mfes[editor.id]);
      }
      // }
    }

    // Se guarda una instancia por cada nuevo popup que se instancia. Los popup se reutilizan entre enditores por tanto, si no existe el popup del id, se tira del primer editor que instanció el popup de fórmulas
    function getMathfiledInstance() {
      var $popup = editor.popups.get('mathPluginv3.popup');
      let popupid = $popup ? $popup.find('.fr-math-v3-insert-layer').data('popup-id') : undefined;
      // console.log('Popup id: ', popupid || editor.id || firstEditor)
      return mfes[popupid || editor.id] || mfes[firstEditor];
    }

    var currentElement,
      $ = editor.$;
    // Methods visible outside the plugin.
    return {
      _init: _init,
      showPopup: showPopup,
      hidePopup: hidePopup,
      insert: insert,
      remove: remove,
      edit: edit,
      update: update,
    };
  };

  // Define an icon and command for the button that opens the custom popup.
  FroalaEditor.DefineIcon('mathIcon', { NAME: 'mathIcon', SVG_KEY: 'symbols' });

  FroalaEditor.RegisterCommand('mathButtonV3', {
    title: 'Insert math expresion',
    icon: 'mathIcon',
    undo: false,
    focus: false,
    plugin: 'mathPluginv3',
    callback: function() {
      this.mathPluginv3.showPopup(false);
    },
  });
  FroalaEditor.RegisterQuickInsertButton('mathButtonQI', {
    title: 'Insert math expression',
    icon: 'mathIcon',
    undo: false,
    focus: false,
    plugin: 'mathPluginv3',
    callback: function() {
      this.mathPluginv3.showPopup(true);
    },
  });

  FroalaEditor.DefineIcon('mathPluginv3Remove', {
    NAME: 'trash',
    SVG_KEY: 'remove',
  });
  FroalaEditor.RegisterCommand('mathPluginv3Remove', {
    title: 'Remove',
    undo: false,
    callback: function() {
      this.mathPluginv3.remove();
    },
  });

  FroalaEditor.DefineIcon('mathPluginv3Edit', { NAME: 'edit', SVG_KEY: 'edit' });
  FroalaEditor.RegisterCommand('mathPluginv3Edit', {
    title: 'Edit',
    undo: false,
    callback: function() {
      this.mathPluginv3.edit();
    },
  });

  FroalaEditor.DefineIcon('popupClosev3', { NAME: 'times', SVG_KEY: 'back' });
  FroalaEditor.RegisterCommand('popupClosev3', {
    title: 'Close',
    undo: false,
    focus: false,
    callback: function() {
      this.mathPluginv3.hidePopup();
    },
  });

  FroalaEditor.RegisterCommand('mathInsertv3', {
    focus: false,
    plugin: 'mathPluginv3',
    callback: function() {
      this.mathPluginv3.insert();
    },
  });

  FroalaEditor.RegisterCommand('mathUpdatev3', {
    focus: false,
    plugin: 'mathPluginv3',
    callback: function() {
      this.mathPluginv3.update();
    },
  });
})();

/*********************************************************************************************/
/** UTILS: Polyfill, añadir metodos a los elementos getEventListeners y clearEventListeners  */
/*********************************************************************************************/
/**
 * Usage:
 *  someElement.getEventListeners([name]) - return list of event listeners, if name is set return array of listeners for that event
 *  someElement.clearEventListeners([name]) - remove all event listeners, if name is set only remove listeners for that event
 * Doc:
 *  https://gist.github.com/cmbaughman/61ad5b49f10832e07b21993a20b94d8a
 */
function getEventListenersPolyfill() {
  if (
    !Element.prototype.hasOwnProperty('getEventListeners') &&
    !Element.prototype.hasOwnProperty('clearEventListeners')
  ) {
    Element.prototype._addEventListener = Element.prototype.addEventListener;
    Element.prototype.addEventListener = function(a, b, c) {
      if (c == undefined) c = false;
      this._addEventListener(a, b, c);
      if (!this.eventListenerList) this.eventListenerList = {};
      if (!this.eventListenerList[a]) this.eventListenerList[a] = [];
      //this.removeEventListener(a,b,c); // TODO - handle duplicates..
      this.eventListenerList[a].push({ listener: b, useCapture: c });
    };

    Element.prototype.getEventListeners = function(a) {
      if (!this.eventListenerList) this.eventListenerList = {};
      if (a == undefined) return this.eventListenerList;
      return this.eventListenerList[a];
    };
    Element.prototype.clearEventListeners = function(a) {
      if (!this.eventListenerList) this.eventListenerList = {};
      if (a == undefined) {
        for (var x in this.getEventListeners()) this.clearEventListeners(x);
        return;
      }
      var el = this.getEventListeners(a);
      if (el == undefined) return;
      for (var i = el.length - 1; i >= 0; --i) {
        var ev = el[i];
        this.removeEventListener(a, ev.listener, ev.useCapture);
      }
    };

    Element.prototype._removeEventListener = Element.prototype.removeEventListener;
    Element.prototype.removeEventListener = function(a, b, c) {
      if (c == undefined) c = false;
      this._removeEventListener(a, b, c);
      if (!this.eventListenerList) this.eventListenerList = {};
      if (!this.eventListenerList[a]) this.eventListenerList[a] = [];

      // Find the event in the list
      for (var i = 0; i < this.eventListenerList[a].length; i++) {
        if ((this.eventListenerList[a][i].listener == b, this.eventListenerList[a][i].useCapture == c)) {
          // Hmm..
          this.eventListenerList[a].splice(i, 1);
          break;
        }
      }
      if (this.eventListenerList[a].length == 0) delete this.eventListenerList[a];
    };
  }
}
